import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { getCourses } from "../../../../../actions/training";
import ConfirmModal from "../ConfirmModal";

toast.configure();

const Offline = (props) => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);

  // const courseData = [
  //   {
  //     id: 1,
  //     title: "The Complete Course for React",
  //     instructor: "Sundhar Kumar",
  //     position: "Team Lead TCS",
  //     date: "06/07/2024",
  //     time: "10:30 - 1:00",
  //     image: "/images/pexels-katerina-holmes-5905709.jpg",
  //   },
  //   {
  //     id: 2,
  //     title: "Advanced Node.js Concepts",
  //     instructor: "Priya Sharma",
  //     position: "Senior Developer Infosys",
  //     date: "12/08/2024",
  //     time: "2:00 - 5:00",
  //     image: "/images/pexels-katerina-holmes-5905709.jpg",
  //   },
  //   {
  //     id: 3,
  //     title: "Mastering MongoDB",
  //     instructor: "Ravi Verma",
  //     position: "Database Admin Wipro",
  //     date: "20/09/2024",
  //     time: "11:00 - 1:30",
  //     image: "/images/pexels-katerina-holmes-5905709.jpg",
  //   },
  // ];

  useEffect(() => {
    setLoading(true);
    let params = {
      course_mode: "offline"
    }
    props.getCourses(setLoading, params);
  }, []);

  useEffect(() => {
    setCourseData(props.courses);
  }, [props.courses]);

  return (
    <div className="position-relative">
      <div className="row">
        {courseData ? (
          courseData.map((course, index) => (
            <div
              key={index}
              className="col-12 col-sm-6  mb-4"
            // onMouseEnter={() => setHoveredCard(index)}
            // onMouseLeave={() => setHoveredCard(null)}
            >
              <div
                className="card p-2 course-card"
                style={{
                  position: "relative",
                  height: hoveredCard == index ? "350px" : null,
                  transition: "height 0.3s ease",
                }}
              >
                {hoveredCard === index ? (
                  <></>
                ) : (
                  // <div className="hover-card new-hovered-card card-body  ">
                  //   <h5>More Information </h5>
                  //   <p>{course.title}</p>
                  //   <p>{course.instructor}</p>
                  //   <p>{course.position}</p>
                  //   <p>{course.date}</p>
                  //   <p>{course.time}</p>
                  //   <div className="btn btn-sm bg-dark-light text-white">
                  //     Join Now
                  //   </div>
                  // </div>
                  <>
                    <div className="card-img">
                      <img
                        className="card-img-top"
                        src={course.image}
                        alt="Card image"
                        height={200}
                      />
                    </div>
                    <div className="card-body d-flex flex-column pt-3">
                      <div>
                        <b className="fs-6 ellipsis">{course.title}</b>
                      </div>
                      <div className="smaller text-black-50">
                        {course.instructor} {course.position}
                      </div>
                      {/* <div>
                      <b className="small">Date:</b>
                      <span className="ps-2 smaller">{course.date}</span>
                    </div>
                    <div>
                      <b className="small">Time:</b>
                      <span className="ps-2 smaller">{course.time}</span>
                    </div> */}
                      <div className="btn btn-sm bg-dark-light mt-2 button-hover">
                        Learn Now
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">No Data Found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  courses: state.training.userCourses,
  enrollres: state.training.enrollres,
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, { getCourses })(Offline);
