import TrainingService from "../services/training.service";
import { toast } from "react-toastify";
import {
  CREATE_MENUS,
  GET_MENUS,
  GET_LANGUAGES,
  GET_LEVELS,
  GET_TRAINING_CATEGORY,
  GET_COURSES,
  GET_ALL_COURSES,
  DELETE_COURSE,
  CREATE_COURSE,
  CHANGE_ORDER,
  ADD_BATCH,
  DELETE_SESSION,
  GET_CURRENCIES,
  ADD_SESSION,
  ADD_ASSOCIATE,
  DELETE_ASSOCIATE,
  ADD_FINANCE,
  DELETE_FINANCE,
  ADD_FEEDBACK,
  DELETE_FEEDBACK,
  ENROLL_COURSE,
  ADD_COURSE_DETAILS,
  POST_ENROLL,
  ADD_FAVOURITE,
} from "../actions/types";

export const getMenus = () => async (dispatch) => {
  try {
    const res = await TrainingService.getMenus();

    dispatch({
      type: GET_MENUS,
      payload: res?.result.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createMenus = (menuData) => async (dispatch) => {
  try {
    const res = await TrainingService.createMenus(menuData);

    dispatch({
      type: CREATE_MENUS,
      payload: res.result.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getLanguage = () => async (dispatch) => {
  try {
    const res = await TrainingService.getAllLanguages();

    dispatch({
      type: GET_LANGUAGES,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getLevels = () => async (dispatch) => {
  try {
    const res = await TrainingService.getAllLevels();

    dispatch({
      type: GET_LEVELS,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getTrainingCategory = () => async (dispatch) => {
  try {
    const res = await TrainingService.getTrainingCategory();

    dispatch({
      type: GET_TRAINING_CATEGORY,
      payload: res?.details?.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getCourses = (setLoading, data) => async (dispatch) => {
  try {
    const res = await TrainingService.getCourses();
    if (setLoading) {
      setLoading(false);
    }
    dispatch({
      type: GET_COURSES,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const postEnroll = (setBtnLoad, data) => async (dispatch) => {
  try {
    const res = await TrainingService.postEnroll(data);
    console.log(res);
    if (setBtnLoad) {
      setBtnLoad(false);
    }
    dispatch({
      type: POST_ENROLL,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addFavourite = (data) => async (dispatch) => {
  try {
    const res = await TrainingService.addFavourite(data);
    dispatch({
      type: ADD_FAVOURITE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getAllCourses = (setLoading, data) => async (dispatch) => {
  try {
    const res = await TrainingService.getAllCourses(data);
    setLoading(false);
    dispatch({
      type: GET_ALL_COURSES,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteCourse = (id, setDeleteLoad) => async (dispatch) => {
  try {
    const res = await TrainingService.deleteCourse(id);
    if (setDeleteLoad) {
      setDeleteLoad(false);
    }
    dispatch({
      type: DELETE_COURSE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createCourse = (courseData) => async (dispatch) => {
  try {
    const res = await TrainingService.createCourse(courseData);
    if (res.status === true) {
      if (courseData.get("course_id")) {
        toast.success("Course updated successfully");
      } else {
        toast.success("Course created successfully");
      }
    }
    dispatch({
      type: CREATE_COURSE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const changeOrder = (orderData) => async (dispatch) => {
  try {
    const res = await TrainingService.changeOrder(orderData);

    dispatch({
      type: CHANGE_ORDER,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addBatch = (batchData, setloading) => async (dispatch) => {
  try {
    const res = await TrainingService.addBatch(batchData);

    dispatch({
      type: ADD_BATCH,
      payload: res,
    });
    if (res?.status == true) {
      if (batchData.coursebatch_id !== null) {
        toast.success("Batch Updated successfully");
      } else {
        toast.success("Batch Added successfully");
      }
    } else {
      toast.error("Please try again");
    }
    if (setloading) setloading(false);
  } catch (err) {
    console.log(err);
    if (setloading) setloading(false);
    toast.error("Please try again");
  }
};
export const deleteBatch = (data, setShowDeleteModal) => async (dispatch) => {
  try {
    const res = await TrainingService.deleteBatch(data);
    setShowDeleteModal({
      showModal: false,
    });

    dispatch({
      type: GET_COURSES,
      payload: res?.data,
    });
    if (res?.status == true) {
      toast.success("Batch deleted successfully");
    } else {
      toast.error("Please try again");
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteSession = (sessionData) => async (dispatch) => {
  try {
    const res = await TrainingService.deleteSession(sessionData);

    dispatch({
      type: DELETE_SESSION,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getCurrencies = () => async (dispatch) => {
  try {
    const res = await TrainingService.getCurrencies();

    dispatch({
      type: GET_CURRENCIES,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addSession = (sessionData, setShowSpinner) => async (dispatch) => {
  try {
    const res = await TrainingService.addSession(sessionData);
    setShowSpinner(false);

    dispatch({
      type: ADD_SESSION,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addAssociate = (associateData) => async (dispatch) => {
  try {
    const res = await TrainingService.addAssociate(associateData);

    dispatch({
      type: ADD_ASSOCIATE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAssociate = (associateData) => async (dispatch) => {
  try {
    const res = await TrainingService.deleteAssociate(associateData);

    dispatch({
      type: DELETE_ASSOCIATE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addFinance = (financeData) => async (dispatch) => {
  try {
    const res = await TrainingService.addFinance(financeData);

    dispatch({
      type: ADD_FINANCE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteFinance = (financeData) => async (dispatch) => {
  try {
    const res = await TrainingService.deleteAssociate(financeData);

    dispatch({
      type: DELETE_FINANCE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addFeedback = (feedbackData) => async (dispatch) => {
  try {
    const res = await TrainingService.addFeedback(feedbackData);

    dispatch({
      type: ADD_FEEDBACK,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteFeedback = (feedbackData) => async (dispatch) => {
  try {
    const res = await TrainingService.deleteFeedback(feedbackData);

    dispatch({
      type: DELETE_FEEDBACK,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

//online

export const enrollCourse = (data, setShowLoader) => async (dispatch) => {
  try {
    const res = await TrainingService.enrollCourse(data);
    setShowLoader(false);
    dispatch({
      type: ENROLL_COURSE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};
export const addCourseDetails = (data) => async (dispatch) => {
  try {
    const res = await TrainingService.addCourseDetails(data);

    if (res.status === true) {
      if (data?.course_aboutus) {
        toast.success("Course details added successfully");
      } else {
        toast.success("Image uploaded successfully");
      }
    }

    dispatch({
      type: CREATE_COURSE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};
