import { Suspense, useEffect, useState } from "react";

import { connect, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Navbar, NavDropdown, Form, FormControl, Nav } from "react-bootstrap";
import {
  BsSearch,
  BsBoxArrowRight,
  BsQuestionCircle,
  BsKeyFill,
  BsPeople,
  BsBell,
  BsFillPersonFill,
} from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
// import { BiBell } from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.min.css";
import { Award, Users, UsersRound } from "lucide-react";

// import ThemeColor from "../ThemeColor";
import ProfileService from "../../services/profile.service";
import { filterData } from "../../actions/dashboradCommon";
import { userLogout } from "../../actions/auth";
import {
  getGoolgleUser,
  getUser,
  getUserData,
  getUserRole,
  getUserRoleId,
} from "../../utils/UserDetails";
import {
  getProfileDetails,
  setPrimaryProfile,
  getProfile,
  setActiveProfile,
} from "../../actions/profile";
import CommonService from "../../services/common.service";
import { changeRole } from "../../actions/subscription";
import ConfirmModal from "../../components/Common/ConfirmModal";
import CommonLimitModal from "../Common/CommonLimitModal";

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = getUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [masterRoles, setMasterRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  // Extract the 'role' parameter
  const roleParam = queryParams?.get("role");
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const isActive = location.pathname.includes("/contact");

  useEffect(() => {
    if (currentUser) {
      props.getProfileDetails(1);

      if (!getMasterRoles()) {
        getRoles();
      }
    }
    CommonService.scrollToTop();
  }, []);

  useEffect(() => {
    if (selectedRole !== "") {
      let role =
        selectedRole?.charAt(0).toUpperCase() +
        selectedRole?.slice(1).toLowerCase();
      setModalData({
        header: `Are you sure you want to change the role to ${role} ?`,
      });
      setIsOpen(true);
    }
  }, [selectedRole]);

  useEffect(() => {
    setSelectedRole("");
    setIsOpen(false);
  }, [props.changedRoleData]);

  useEffect(() => {
    if (props.profile) {
      let newData = props.profile;

      dispatch(props.setActiveProfile(newData));
    }
  }, [props.profile]);

  const getRoles = async () => {
    let resultData = await CommonService.getRoles();

    if (resultData && resultData?.status === 2000) {
      localStorage.setItem("MasterRoles", JSON.stringify(resultData?.data));
    }
  };

  const getMasterRoles = () => {
    const data = localStorage.getItem("MasterRoles");
    if (data) return JSON.parse(data);
    else return null;
  };

  const handleActiveProfile = async (profile) => {
    if (profile.user_profile_name !== props.activeProfile?.user_profile_name) {
      let profileData = {
        profileId: profile?.user_profile_id,
      };

      props.getProfile(profileData);
    }
  };

  const setPrimaryProfile = (activeProfile) => {
    props.setPrimaryProfile(activeProfile);
  };

  const handleSearchChange = (event) => {
    let filterContent = { ...props.mainFilter };
    filterContent.searchTerm = event.target.value;
    props.filterData(filterContent);
  };

  const onLogoutHandler = () => {
    props.userLogout(history);
  };

  // const headerProfilePic = () => {
  //   return (
  //     <img
  //       className="p-1 header-profile-pic"
  //       src={
  //         props.activeProfile.user_profile_image_url
  //           ? props.activeProfile.user_profile_image_url
  //           : ProfileService.defaultProfilePic()
  //       }
  //       onError={(e) => {
  //         e.target.onerror = null;
  //         e.target.src = process.env.PUBLIC_URL + "/images/icons/profile1.jpg";
  //       }}
  //     />
  //   );
  // };

  const handleProfileClick = (e) => {
    e.stopPropagation();
  };

  const handleRoles = (e) => {
    e.stopPropagation();
  };


  const handleChangeRole = async () => {
    setLoading(true);

    let params = {
      role_type: selectedRole,
      role_id: getUserRoleId(),
    };
    props.changeRole(params, setLoading, history);
  };

  const toggleModal = () => {
    setSelectedRole("");
    setIsOpen(false);
  };

  return (
    <>
      <header className="header pt-1 fixed-top">
        <Navbar expand="lg" sticky="top">
          <div className="container-xxl ">
            <div class="d-flex flex-grow-1 justify-content-center justify-content-lg-start w-25">
              <Navbar.Brand className="tjunction-logo logo-marginleft">
                <Link to="/">
                  {props.darkTheme ? (
                    <img
                      className="logo-image"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/tjunction_black_bg_logo.png"
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      className="logo-image"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/tjunction_white_bg_logo.png"
                      }
                      alt=""
                    />
                  )}
                </Link>
              </Navbar.Brand>
            </div>

            {/* <div
              className="search-bar"
              style={{ marginLeft: "5px", minWidth: "760px" }}
            >
              <Form inline className="search-form d-flex align-items-center">
                <FormControl
                  type="text"
                  name="query"
                  placeholder="Search"
                  title="Enter search keyword"
                  onChange={handleSearchChange}
                  value={props.mainFilter.searchTerm}
                />
                <button type="submit" title="Search">
                  <BsSearch />
                </button>
              </Form>
            </div> */}

            {currentUser && getMasterRoles() ? (
              <>
                {/* <div className="mx-2 ">
                  <a
                    href={`/${getUserRole()?.toLowerCase()}/contact`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "underline", fontWeight: "bold" }}
                  >
                    Group
                  </a>
                </div> */}
                {/* <div className="w-50">
                  <ul className="d-flex align-items-center my-auto justify-content-center ">
                    <div className="d-flex ">
                      <Nav.Link
                        className="ml-4"
                        style={{
                          textDecoration: isActive ? "underline" : "none",
                          fontWeight: 500,
                          marginBottom: "4px",
                        }}
                        href={`/${getUserRole()?.toLowerCase()}/contact`}
                        target="_blank"
                        onMouseEnter={(e) =>
                          (e.target.style.textDecoration = "underline")
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.textDecoration = isActive
                            ? "underline"
                            : "none")
                        }
                      >
                        <i
                          className="bi bi-people-fill mx-1"
                          style={{ fontSize: "14px" }}
                        ></i>
                        Group
                      </Nav.Link>
                    </div>
                  </ul>
                </div> */}
                <Navbar className="header-nav nav-menu-margin">
                  <Nav className="d-flex align-items-center">
                    {/* <Nav.Link
                      className="ml-4 nav-link-custom d-none d-lg-block"
                      style={{
                        color: "black",
                        fontWeight: 500,
                      }}
                    >
                      <>
                        <UsersRound className="header-menu-icon" />
                        <span className=" d-none d-md-inline">My People</span>
                      </>
                    </Nav.Link>

                    <Nav.Link
                      className="ml-4 nav-link-custom d-none d-lg-block"
                      style={{
                        color: "black",
                        fontWeight: 500,
                      }}
                    >
                      <>
                         <Award className="header-menu-icon" />
                        <span className=" d-none d-md-inline">My Rewards</span>
                      </>
                    </Nav.Link> */}

                    <Nav.Link
                      className="ml-4 nav-link-custom"
                      style={{
                        color: isActive ? "#0084fb" : "black",
                        fontWeight: 500,
                        // marginBottom: "4px",
                      }}
                      href={
                        isActive
                          ? ""
                          : `/${getUserRole()?.toLowerCase()}/contact`
                      }
                      target="_blank"
                    // onMouseEnter={(e) => (e.target.style.color = "#0084fb")}
                    // onMouseLeave={(e) => (e.target.style.color = "black")}
                    >
                      <>
                        {/* <i
                          className="bi bi-people-fill mx-1 "
                          style={{ fontSize: "16px" }}
                        ></i> */}
                        <Users className="header-menu-icon" />
                        <span className=" d-none d-md-inline">My Group</span>
                      </>
                    </Nav.Link>
                  </Nav>
                </Navbar>
                <Navbar className="header-nav ms-auto  ">
                  <Nav className="d-flex align-items-center">
                    <div className="d-none d-lg-block">
                      {getMasterRoles() && getMasterRoles()?.length > 0 && (
                        <NavDropdown
                          className="currentprfl "
                          alignleft="true"
                          title={
                            <span
                              style={{
                                color: "black",
                                fontWeight: 500,
                                fontSize: "16px",
                              }}
                            >
                              {getUserRole()?.charAt(0)?.toUpperCase() +
                                getUserRole()?.slice(1)?.toLowerCase()}
                            </span>
                          }
                          id="basic-nav-dropdown"
                          align="end"
                        >
                          {getMasterRoles()?.map((roles, roleKey) => {
                            const isSelected =
                              roles.roles_master_code === getUserRole();

                            return roles.roles_master_name ? (
                              <NavDropdown.Item
                                key={roleKey}
                                onClick={
                                  !isSelected
                                    ? () =>
                                        setSelectedRole(roles.roles_master_code)
                                    : undefined
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  id="tick-mark-check"
                                  className={isSelected ? "" : "invisible"}
                                />{" "}
                                {roles.roles_master_name}
                              </NavDropdown.Item>
                            ) : (
                              <></>
                            );
                          })}
                        </NavDropdown>
                      )}
                    </div>
                    {/* <NavDropdown alignLeft title={(props.darkTheme) ?
                      <a href="#" className={(props.darkTheme) ? "notification-dark padding-and-margin-for-notification" : "notification padding-and-margin-for-notification"}>
                        <BsBell />
                        <span className="badge">3</span>
                      </a> :
                      <a href="#" className='notification pr-5 padding-and-margin-for-notification'>
                        <BsBell />
                        <span className="badge">3</span>
                      </a>}
                      id="basic-nav-dropdown pr-0" className='dropdown-menu-end notifications pr-0 ' >
                      <li className="dropdown-header">
                        You have 1 new notifications
                        <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li className="notification-item">
                        <BsExclamationCircle />
                        <div>
                          <NavDropdown.Item>
                            <h4>Lorem Ipsum</h4>
                            <p>Quae dolorem earum veritatis oditseno</p>
                            <p>30 min. ago</p>
                          </NavDropdown.Item>
                        </div>
                      </li>

                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li className="dropdown-footer">
                        <a href="#">Show all notifications</a>
                      </li>
                    </NavDropdown> */}
                    {/* <NavDropdown
                      alignLeft
                      title={
                        props.darkTheme ? (
                          <a
                            href="#"
                            className={
                              props.darkTheme
                                ? "notification-dark"
                                : "notification"
                            }
                          >
                            <BsChatLeftText />
                            <span className="badge">3</span>
                          </a>
                        ) : (
                          <a
                            href="#"
                            className={
                              props.darkTheme
                                ? "notification-dark padding-and-margin-for-notification"
                                : "notification padding-and-margin-for-notification"
                            }
                          >
                            <BsChatLeftText />
                            <span className="badge">3</span>
                          </a>
                        )
                      }
                      id="basic-nav-dropdown"
                      className="dropdown-menu-end notifications pr-0"
                    >
                      <li className="dropdown-header">
                        You have 2 new messages
                        <a href="#">
                          <span className="badge rounded-pill bg-primary p-2 ms-2">
                            View all
                          </span>
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>

                      <NavDropdown.Item className="message-item">
                        <a href="#">
                          <img
                            src="assets/img/messages-1.jpg"
                            alt=""
                            className="rounded-circle"
                          />
                          <div>
                            <h4>Maria Hudson</h4>
                            <p>
                              Velit asperiores et ducimus soluta repudiandae
                              labore officia est ut...
                            </p>
                            <p>4 hrs. ago</p>
                          </div>
                        </a>
                      </NavDropdown.Item>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>

                      <NavDropdown.Item className="message-item">
                        <a href="#">
                          <img
                            src="assets/img/messages-2.jpg"
                            alt=""
                            className="rounded-circle"
                          />
                          <div>
                            <h4>Anna Nelson</h4>
                            <p>
                              Velit asperiores et ducimus soluta repudiandae
                              labore officia est ut...
                            </p>
                            <p>6 hrs. ago</p>
                          </div>
                        </a>
                      </NavDropdown.Item>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>

                      <li className="dropdown-footer">
                        <a href="#">Show all messages</a>
                      </li>
                    </NavDropdown> */}

                    <NavDropdown
                      alignleft="true"
                      title={
                        <img
                          className="profile-icon rounded-circle currentprfl img-padding"
                          src={
                            props.activeProfile &&
                            props.activeProfile.user_profile_image_url
                              ? props.activeProfile.user_profile_image_url
                              : ProfileService.defaultProfilePic()
                          }
                          alt="profile-logo"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              process.env.PUBLIC_URL +
                              "/images/icons/profile1.jpg";
                          }}
                        />
                      }
                      id="basic-nav-dropdown1"
                      className="profile-img-dropdown mt-2"
                    >
                      <li className="notification-item">
                        <NavDropdown.Item
                          className="m-0"
                          style={{ minWidth: "180px" }}
                        >
                          <li className="dropdown-header py-1">
                            <h6>
                              <b> {getUserData()?.user_name}</b>
                            </h6>
                            {/* <h6>{props.activeProfile.user_profile_name}</h6> */}
                            {/* <span>{props.activeProfile.user_profile_name}</span> */}
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li onClick={handleProfileClick}>
                            <a className="dropdown-item d-flex align-items-center toggle-opacity-css py-0">
                              <BsPeople className="for-logout" />
                              <span>
                                <NavDropdown
                                  className="profile-dropdown-item"
                                  alignleft={true}
                                  title={
                                    props.activeProfile?.user_profile_name
                                      ? props.activeProfile?.user_profile_name
                                      : "Profiles"
                                  }
                                  id="basic-nav-dropdown"
                                  align="start"
                                >
                                  {props.userProfiles?.map((profile) => {
                                    return profile.user_profile_name ? (
                                      <NavDropdown.Item
                                        key={profile.user_profile_id}
                                        onClick={() =>
                                          handleActiveProfile(profile)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          id="tick-mark-check"
                                          style={{ marginLeft: '10px' }}
                                          className={
                                            profile.user_profile_name ===
                                            props.activeProfile
                                              ?.user_profile_name
                                              ? ""
                                              : "invisible"
                                          }
                                        />{" "}
                                        {profile.user_profile_name}
                                      </NavDropdown.Item>
                                    ) : (
                                      <></>
                                    );
                                  })}
                                </NavDropdown>
                              </span>
                            </a>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li onClick={handleRoles} className="d-block d-lg-none">
                            <a className="dropdown-item d-flex align-items-center toggle-opacity-css py-0">
                              <BsFillPersonFill className="for-logout" />
                              <span>
                                <NavDropdown
                                  className="profile-dropdown-item"
                                  alignleft={true}
                                  title={getUserRole()?.charAt(0)?.toUpperCase() +
                                      getUserRole()?.slice(1)?.toLowerCase()}
                                  id="basic-nav-dropdown"
                                  align="start"
                                >
                                  {getMasterRoles()?.map((roles, roleKey) => {
                                    const isSelected = roles.roles_master_code === getUserRole();

                                    return roles.roles_master_name ? (
                                      <NavDropdown.Item
                                        key={roleKey}
                                        onClick={!isSelected ? () => setSelectedRole(roles.roles_master_code) : undefined}
                                      >
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          id="tick-mark-check"
                                          style={{ marginLeft: '10px' }}
                                          className={
                                            isSelected ? "" : "invisible" }
                                        />{" "}
                                        {roles.roles_master_name}
                                      </NavDropdown.Item>
                                    ) : (
                                      <></>
                                    );
                                  })}
                                </NavDropdown>
                              </span>
                            </a>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li
                            className="dropdown-item d-flex align-items-center padding-and-margin-for-notification"
                            onClick={() => history.push(`/subscription`)}
                          >
                            <BsBell className="for-logout text-muted" />
                            <span>My Subscription</span>
                          </li>
                          {/* <li>
                            <a
                              className="dropdown-item d-flex align-items-center"
                              href="users-profile.html"
                            >
                              <BsPerson className="for-logout" />
                              <span>My Profile</span>
                            </a>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>

                          <li>
                            <a
                              className="dropdown-item d-flex align-items-center"
                              href="users-profile.html"
                            >
                              <BsGear className="for-logout" />
                              <span>Account Settings</span>
                            </a>
                          </li> */}
                          {getGoolgleUser() ? (
                            <></>
                          ) : (
                            <>
                              <li>
                                <hr className="dropdown-divider" />
                              </li>
                              <li
                                className="dropdown-item d-flex align-items-center padding-and-margin-for-notification"
                                onClick={() =>
                                  history.push(
                                    `/${getUserRole()?.toLowerCase()}/ChangePassword`,
                                  )
                                }
                              >
                                <BsKeyFill className="for-logout text-muted" />
                                <span>Change Password</span>
                              </li>
                            </>
                          )}

                          <li>
                            <hr className="dropdown-divider" />
                          </li>

                          <li>
                            <a
                              className="dropdown-item d-flex align-items-center"
                              href="pages-faq.html"
                            >
                              <BsQuestionCircle className="for-logout" />
                              <span>Need Help?</span>
                            </a>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li
                            className="dropdown-item d-flex align-items-center padding-and-margin-for-notification"
                            href="#"
                          >
                            <div>
                              <BsBoxArrowRight className="for-logout" />
                              <span onClick={onLogoutHandler}>Sign Out</span>
                            </div>
                          </li>
                        </NavDropdown.Item>
                      </li>
                    </NavDropdown>

                    {/* <NavDropdown alignLeft title= {<><img className="profile-icon rounded-circle currentprfl " src={(props.activeProfile && props.activeProfile.user_profile_image_url) ? props.activeProfile.user_profile_image_url : ProfileService.defaultProfilePic()}
                                alt="profile-logo"
                                onError={(e)=>{e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + '/images/icons/prfl.png'}}
                              /><span>{props.activeProfile.user_profile_name}</span></>}
                              id="basic-nav-dropdown"> 
                        <li className="notification-item">
                        <NavDropdown.Item>
                        <li className="dropdown-header">
                        <h6><b>{currentUser?.user_name}</b></h6>
                        <span>{props.activeProfile.user_profile_name}</span>
                        </li>
                        <li>
                        <hr className="dropdown-divider"/>
                        </li>
                        <li>
                        <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                            <BsPerson className='for-logout'/>
                            <span>My Profile</span>
                        </a>
                        </li>
                        <li>
                        <hr className="dropdown-divider"/>
                        </li>

                        <li>
                        <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                            <BsGear className='for-logout'/>
                            <span>Account Settings</span>
                        </a>
                        </li>
                        <li>
                        <hr className="dropdown-divider"/>
                        </li>

                        <li>
                        <a className="dropdown-item d-flex align-items-center" href="pages-faq.html">
                            <BsQuestionCircle className='for-logout'/>
                            <span>Need Help?</span>
                        </a>
                        </li>
                        <li>
                        <hr className="dropdown-divider"/>
                        </li>
                        <a className="dropdown-item d-flex align-items-center padding-and-margin-for-notification" href="#">
                            <div>
                            <BsBoxArrowRight className='for-logout'/>
                            <span onClick={onLogoutHandler}>Sign Out</span>
                            </div>
                        </a>
                        </NavDropdown.Item>
                        </li>
                    </NavDropdown> */}
                  </Nav>
                </Navbar>
              </>
            ) : (
              <div className="d-flex navmenu">
                {!currentUser && (
                  <>
                    <Link
                      to="/login"
                      className="btn btn-sm mx-2 font-weight-bold btn-light"
                    >
                      Log In
                    </Link>

                    {/* <Link to="#" className="mx-1 small my-1">
                      Forgot password?
                    </Link> */}

                    <Link
                      to={
                        roleParam ? `/register?role=${roleParam}` : "/register"
                      }
                      className={`profile-gradient-btn btn btn-secondary btn btn-sm`}
                    >
                      Register
                    </Link>
                  </>
                )}
              </div>
            )}
          </div>
        </Navbar>
      </header>
      <Suspense>
        <ConfirmModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          toggleModal={toggleModal}
          modalData={modalData}
          handleFunction={handleChangeRole}
          loading={loading}
        />

        <CommonLimitModal isOpen={modalOpen} setIsOpen={setModalOpen} />
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  userProfiles: state.profile.userProfiles,
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  mainFilter: state.dashboardCommon.mainFilter,
  changedRoleData: state.subscription.changedRoleData,
  profile: state.profile.profile,
});

export default connect(mapStateToProps, {
  getProfileDetails,
  setPrimaryProfile,
  filterData,
  userLogout,
  changeRole,
  getProfile,
  setActiveProfile,
})(Header);
