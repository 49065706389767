import {
  ADD_CONTACT,
  GET_CONTACT,
  CREATE_CONTACT,
  GET_GROUP,
  GET_GRPCONTACT,
  REMOVE_MEMBER,
  ADD_MEMBER,
  GET_MYGROUP,
  GET_GRPDROPDOWN,
} from "../actions/types";

const initialState = {
  addContact: [],
  contact: [],
  addgroup: [],
  group: [],
  groupMember: [],
  removedMember: [],
  addedMember: [],
  mygroup: [],
  grpdropdown: [],
};
const ContactReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_CONTACT:
      return {
        ...state,
        addContact: payload,
      };
    case GET_CONTACT:
      return {
        ...state,
        contact: payload,
      };
    case CREATE_CONTACT:
      return {
        ...state,
        addgroup: payload,
      };
    case GET_GROUP:
      return {
        ...state,
        group: payload,
      };
    case GET_MYGROUP:
      return {
        ...state,
        mygroup: payload,
      };
    case GET_GRPCONTACT:
      return {
        ...state,
        groupMember: payload,
      };
    case REMOVE_MEMBER: {
      return {
        ...state,
        removedMember: payload,
      };
    }
    case GET_GRPDROPDOWN: {
      return {
        ...state,
        grpdropdown: payload,
      };
    }
    case ADD_MEMBER: {
      return {
        ...state,
        addedMember: payload,
      };
    }
    default:
      return state;
  }
};
export default ContactReducer;
