import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { reportSpam } from "../../../../actions/dashboradCommon";
import FormLoader from "../../../../components/FormLoader";
import { connect } from "react-redux";

const SpamModal = ({ show, onHide, post, reportSpam }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  useEffect(() => {
    setSelectedReason("");
  }, [show]);

  const handleSubmit = () => {
    setBtnLoad(true);
    let params = {
      qboard_id: post?.qboard_id,
      report_reason: selectedReason,
    };
    reportSpam(setBtnLoad, params);
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      className="tj-modal"
      backdrop="static"
      keyboard={false}
      centered
      id="qboard-post"
    >
      <Modal.Header closeButton className="d-flex">
        <Modal.Title className="flex-grow-1">Report Spam</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {[
            "Sexual content",
            "Violent or repulsive content",
            "Hateful or abusive content",
            "Harmful or dangerous acts",
            "Spam or misleading",
            "Child abuse",
          ].map((reason, index) => (
            <div key={index} className="d-flex align-items-center gap-3 mb-3">
              <Form.Check
                type="radio"
                name="reportReason"
                label={reason}
                value={reason}
                id={`reason-${index}`}
                checked={selectedReason === reason}
                onChange={(e) => setSelectedReason(e.target.value)}
                className="mx-3 custom-checkbox"
                style={{ fontSize: "16px" }}
              />
            </div>
          ))}

          <div className="d-flex justify-content-end mt-3 mb-5">
            <Button
              variant="primary"
              onClick={() => handleSubmit()}
              disabled={selectedReason == "" ? true : false}
            >
              {btnLoad ? <FormLoader /> : " Report"}
            </Button>
          </div>
        </Form>{" "}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, { reportSpam })(SpamModal);
