import React from "react";

import { connect } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import Header from "./Header";
import AdsMenu from "../AdsMenu";
import FeedLeftMenu from "../../pages/Trainers/dashboard/FeedLeftMenu/FeedLeftMenu";
import ThemeColor from "../ThemeColor";
import CommonAlertModal from "../Common/CommonAlertModal";

const FeedLayout = (props) => {
  let mainClass = props.darkTheme ? "is-dark-theme " : "";
  mainClass += props.feedLeftIsExpand;

  return (
    <div className={mainClass}>
      <Header />

      <div className="d-sm-block  d-md-block d-lg-none toogle-menu  ">
        <NavDropdown
          title={<FontAwesomeIcon icon={faBars} className="text-muted m-2" />}
        >
          <FeedLeftMenu />
        </NavDropdown>
      </div>

      <div
        className="container-xxl primary-bg-color feed-layout"
        style={{ backgroundColor: "#f2f9ff" }}
      >
        <div className="row m-0">
          <div
            className="px-0 mb-2 alert-top-css"
          >
            <CommonAlertModal />
          </div>
          <div className="col-md-2 d-none d-lg-block" id="sidebar-wrapper">
            {/* <div
              className={`text-center pb-3 h5 fw-bold ${props.activeColor}-font`}
            >
              <Link
                to={`/${getUserRole().toLowerCase()}/profile`}
                className={`${props.activeColor}-font`}
              >
                {" "}
                {getUserData()?.user_name}
              </Link>
            </div> */}
            <div className="layout-left-side ms-1">
              <FeedLeftMenu />
            </div>
          </div>
          <div className="col main-frame" id="page-content-wrapper">
            <div className="layout-center-side">{props.children}</div>
          </div>
          <div className="col-md-2 ads-frame">
            <div className="layout-right-side bg-color-changes">
              <div>
                <ThemeColor />
              </div>
              <AdsMenu />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkTheme: state.activetheme.darkTheme,
  feedLeftIsExpand: state.common.feedLeftMenuIsExpand,
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps)(FeedLayout);
