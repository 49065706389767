import {
  ACTIVE_ENROLL,
  ADD_COURSE_FEEDBACK,
  GET_COMPLETED_COURSE,
  GET_ENROLLED_COURSE,
  GET_INTEREST_COURSE,
  GET_TRAINING_ENQUIRES,
} from "../actions/types";

const courseReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ENROLLED_COURSE: {
      return {
        ...state,
        enrolled: payload,
      };
    }
    case GET_INTEREST_COURSE: {
      return {
        ...state,
        interestcourse: payload,
      };
    }
    case GET_COMPLETED_COURSE: {
      return {
        ...state,
        completedcourse: payload,
      };
    }
    case GET_TRAINING_ENQUIRES: {
      return {
        ...state,
        trainingEnquiry: payload,
      };
    }
    case ACTIVE_ENROLL: {
      return {
        ...state,
        enquiryStatus: payload,
      };
    }
    case ADD_COURSE_FEEDBACK: {
      return {
        ...state,
        courseFeedbackData: payload,
      };
    }
    default:
      return state;
  }
};
export default courseReducer;
