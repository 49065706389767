import { useState, useEffect, Suspense } from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import Loader from "react-loader-spinner";

import QboardData from "./QboardData";
import { prototype } from "react-autocomplete";
import Jobs from "../requirements/job/Jobs";
import Training from "../requirements/training/Training";

// Modal wrapper component
const PostModal = ({ show, onHide, post, postKey, setModalData, tabview }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      className="tj-modal"
      backdrop="static"
      keyboard={false}
      id="qboard-post"
    >
      <Modal.Header closeButton className="d-flex">
        <Modal.Title className="flex-grow-1"> Post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {show && post ? (
          tabview === "qboard" ? (
            <QboardData post={post[0]} key={postKey} index={postKey} />
          ) : tabview === "jobs" ? (
            <Jobs jobPosts={post} />
          ) : (
            <Training trainingPosts={post} />
          )
        ) : (
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={50}
            width={50}
            className="loader text-center"
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PostModal;
