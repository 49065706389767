import axios from "axios";
import Axios from "../config/axios.js";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
class SortResumeService {
  sortResume = async (formdata) => {
    const url = process.env.REACT_APP_RECRUITER_URL + "/api-sort-resumes";

    return axios
      .post(url, formdata, { responseType: "blob" }) // Set responseType to 'blob'
      .then(function (response) {
        const blob = new Blob([response.data], { type: "application/zip" }); // Use response.data
        saveAs(blob, "sortResume.zip");
        console.log(response);
      })
      .catch((error) => {
        console.error("Error downloading the file", error);
      });
  };
  getJdbased = async (formdata, setSubmitting) => {
    const url = process.env.REACT_APP_RECRUITER_URL + "/api-sort-resumes-jd";

    return axios
      .post(url, formdata, { responseType: "blob" }) // Set responseType to 'blob'
      .then(function (response) {
        const contentType = response.headers["content-type"];
        if (contentType.includes("application/json")) {
          setSubmitting(false);
          return response.data.text().then((text) => {
            const jsonData = JSON.parse(text);
            toast.error(jsonData.message);
            console.log("JSON Response:", jsonData);
          });
        } else {
          const blob = new Blob([response.data], { type: "application/zip" }); // Use response.data
          saveAs(blob, "sortresume_jd.zip");

          setSubmitting(false);
        }
      })
      .catch((error) => {
        console.error("Error downloading the file", error);
        setSubmitting(false);
      });
  };

  getResumeRanking = async (data) => {
    const url = process.env.REACT_APP_RECRUITER_URL + "/resume-ranking";

    return await Axios.post(url, data)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
const SortResumeServiceInstance = new SortResumeService();
export default SortResumeServiceInstance;
