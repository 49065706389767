import { useEffect, useRef, useState } from "react";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import FormLoader from "../../../../components/FormLoader";
import { getUser, getUserData } from "../../../../utils/UserDetails";
import { qboradPostData } from "../../../../actions/qboard";
import { setModalData } from "../../../../actions/dashboradCommon";
import { getInterests } from "../../../../actions/profile";
import { DropdownTreeSelect } from "../../../../components/InterestDropdown";

const QboardModal = (props) => {
  const currentUser = getUserData();
  const formikRef = useRef();

  let qboradFormValues = {
    qboard_title: "Post Your Query",
    qboard_id: "",
    qboard_category_id: "",
    qboard_description: "",
    qboard_parent_id: "",
    qboard_hashtag: "",
    qboard_temp_hashtag: "",
    qboard_hashtag_view:
      props?.modalData?.selectedInterest?.name &&
      props?.modalData?.activeTab !== "My Connect"
        ? [{ common_hashtag_tag: props?.modalData?.selectedInterest?.name }]
        : [],
    qboard_shared_links: "",
    qboard_document_upload: "",
    qboard_image_upload: "",
    qboard_video_upload: "",
    key_table: props.keyTable,
    qboard_post_enable: 1,
    qboard_user_profile_name: "",
    qboard_profile_id: "",
    qboard_youtube_link: "",
    qboard_description_order: 1,
    qboard_file_order: 2,
    qboard_profile_id: props.activeProfile?.user_profile_id,
    qboard_user_profile_name: props.activeProfile?.user_profile_name,
  };

  const [interestData, setInterestData] = useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [myInterestData, setMyInterestData] = useState([]);

  useEffect(() => {
    setSelectedNodeKey(props?.modalData?.selectedInterest?.value);
  }, [props?.modalData?.selectedInterest]);

  if (props.modalData.qboardObj?.qboard_parent_id) {
    qboradFormValues = { ...qboradFormValues, ...props.modalData.qboardObj };
    qboradFormValues.qboard_title = "Answer the Query";
  }

  if (props.modalData.qboardObj?.qboard_id) {
    qboradFormValues = { ...qboradFormValues, ...props.modalData.qboardObj };
    qboradFormValues.qboard_category_id = parseInt(
      qboradFormValues.qboard_category_id,
    )
      ? qboradFormValues.qboard_category_id
      : "";
    qboradFormValues.qboard_shared_links = qboradFormValues?.common_urls
      ?.common_url_link
      ? qboradFormValues.common_urls.common_url_link
      : "";
    qboradFormValues.qboard_title = "Update the Query";
    qboradFormValues.qboard_hashtag = qboradFormValues?.common_hash_tag_value
      ? qboradFormValues.common_hash_tag_value.join(",")
      : "";
    qboradFormValues.qboard_hashtag_view = qboradFormValues?.common_hash_tag
      ? qboradFormValues.common_hash_tag
      : [];

    qboradFormValues.common_files.map((val) => {
      let fileType = val.common_file_upload_material_type.split("/");
      let fileUrl = val.common_file_upload_server_url.split("/").pop();
      let fileDetails = {
        name: fileUrl,
        common_file_upload_id: val.common_file_upload_id,
      };

      if (fileType.includes("application")) {
        qboradFormValues.qboard_document_upload = fileDetails;
        qboradFormValues.qboard_document_upload_id = val.common_file_upload_id;
      }

      if (fileType.includes("image")) {
        qboradFormValues.qboard_image_upload = fileDetails;
        qboradFormValues.qboard_image_upload_id = val.common_file_upload_id;
      }

      if (fileType.includes("video")) {
        if (val.common_file_is_youtube) {
          qboradFormValues.qboard_youtube_link =
            val.common_file_upload_server_url;
          qboradFormValues.qboard_video_upload_id = val.common_file_upload_id;
        } else {
          qboradFormValues.qboard_video_upload = fileDetails;
          qboradFormValues.qboard_video_upload_id = val.common_file_upload_id;
        }
      }
    });
  }

  const qboardSchema = Yup.object().shape({
    qboard_description: Yup.string().required("Enter Description"),
  });

  // useEffect(() => {
  //   if (currentUser) {
  //     props.getInterests();
  //   }
  // }, []);

  useEffect(() => {
    if (props.allInterests) {
      let allInterestsData = props.allInterests;
      allInterestsData = allInterestsData?.map((item) => {
        return {
          key: item.interests_id,
          value: item.interests_id,
          label: item?.interests_name,
          selectable: false,
          children: item.children?.map((item1) => {
            return {
              key: item1.interests_id,
              value: item1.interests_id,
              label: item1.interests_name,
            };
          }),
        };
      });
      setInterestData(allInterestsData);
    }
  }, [props.allInterests]);

  useEffect(() => {
    if (props?.myInterest) {
      if (props?.myInterest) {
        let allInterestsData = props?.myInterest;
        allInterestsData = allInterestsData?.map((item) => {
          return {
            key: item.interests_id,
            value: item.interests_id,
            label: item?.interests_name,
            selectable: false,
            children: item.children?.map((item1) => {
              return {
                key: item1.interests_id,
                value: item1.interests_id,
                label: item1.interests_name,
              };
            }),
          };
        });
        let data = {
          key: "",
          value: "",
          label: "Filter",
        };
        setMyInterestData([data, ...allInterestsData]);
      } else {
        setMyInterestData([]);
      }
    }
  }, [props?.myInterest]);

  useEffect(() => {
    if (props.modalData.qboardModal) formikRef.current?.resetForm();
  }, [props.modalData.qboardModal]);

  const closeModal = () => {
    let modalContent = { ...props.modalContent };
    props.setModalData({ ...modalContent, qboardModal: false });
  };

  const getValueKey = (obj, reultkey) => {
    let getValue = [];
    if (obj) {
      obj.forEach((value) => {
        getValue.push(value[reultkey]);
      });
    }
    return getValue;
  };

  const addHashTag = (value, values, setFieldValue) => {
    if (value) {
      let updatedValue = {
        common_hashtag_tag: value.charAt(0) === "#" ? value : "#" + value,
      };

      setFieldValue("qboard_hashtag_view", [
        ...values.qboard_hashtag_view,
        updatedValue,
      ]);
      setFieldValue("qboard_temp_hashtag", "");
    }
  };

  const removeHashTag = (key, values, setFieldValue) => (event) => {
    event.preventDefault();
    values.qboard_hashtag_view.splice(key, 1);
    setFieldValue("qboard_hashtag_view", values.qboard_hashtag_view);
  };

  const removefileData = (fieldname, updatedId, setFieldValue) => {
    setFieldValue(fieldname, "");
  };

  const handleSelect = (value, values, setFeildValue) => {
    const selectedInterest = props.allInterests
      ?.flatMap((item) => item.children || []) // Flatten all children
      .find((item1) => item1.interests_id === value); // Find the first match
    setSelectedNodeKey(value);
    addHashTag(selectedInterest?.interests_name, values, setFeildValue);
  };

  const orderChange = (event, setFieldValue) => {
    setFieldValue(
      event.target.name === "qboard_description_order"
        ? "qboard_file_order"
        : "qboard_description_order",
      event.target.value == 1 ? 2 : 1,
    );
    setFieldValue(event.target.name, event.target.value);
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={qboradFormValues}
        validationSchema={qboardSchema}
        validateOnChange={false}
        onSubmit={(formData, { resetForm, setSubmitting }) => {
          formData.qboard_category_id = selectedNodeKey ? selectedNodeKey : "";

          const groupIds = [];
          if (props?.modalData?.selectedGroup?.group_id) {
            groupIds.push(props.modalData.selectedGroup.group_id);
          } else {
            props?.myGroup.forEach((group) => {
              groupIds.push(group?.group_id);
            });
          }

          formData.group_id =
            props?.modalData?.activeTab === "My Connect" ? groupIds : null;

          props.qboradPostData(formData, resetForm, setSubmitting, closeModal);
        }}
      >
        {(formProps) => {
          const { values, isSubmitting, errors, touched, setFieldValue } =
            formProps;

          return (
            <Form id="qboardpostmodal">
              <Modal
                show={props.modalData.qboardModal}
                size={"lg"}
                className={
                  `tj-modal ` + (props.darkTheme ? "is-dark-theme" : "")
                }
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                centered
                id="qboard-post"
              >
                <Modal.Header closeButton className="d-flex">
                  <Modal.Title className="flex-grow-1">
                    {values.qboard_title}
                    {props?.modalData?.selectedGroup?.group_name
                      ? ` To
                    ${props?.modalData?.selectedGroup?.group_name}`
                      : props?.modalData?.activeTab == "My Connect"
                      ? " To All Group"
                      : ""}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex flex-row-reverse">
                    <h6>Order</h6>
                  </div>
                  <div className="my-3 err-msg-height">
                    <div className="d-flex">
                      <Field
                        name="qboard_description"
                        placeholder={`What is your query, ${currentUser?.user_name}?`}
                        className="form-control form-control-md"
                        rows="1"
                        component="textarea"
                      />
                      <Field
                        name="qboard_description_order"
                        className="form-control form-control-sm display-order"
                        as="select"
                        style={{ height: "53px" }}
                        onChange={(event) => orderChange(event, setFieldValue)}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </Field>
                    </div>
                    <span className="error-message">
                      {errors.qboard_description && touched.qboard_description
                        ? errors.qboard_description
                        : null}
                    </span>
                  </div>

                  {!values.qboard_document_upload &&
                    !values.qboard_image_upload &&
                    !values.qboard_video_upload && (
                      <div className="d-flex">
                        <Field
                          type="text"
                          name="qboard_youtube_link"
                          className="form-control form-control-sm my-3 qboard-file"
                          placeholder="Add your youtube link"
                        />
                        <Field
                          name="qboard_file_order"
                          className="form-control form-control-sm my-3 display-order"
                          as="select"
                          onChange={(event) =>
                            orderChange(event, setFieldValue)
                          }
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </Field>
                      </div>
                    )}

                  <Field
                    type="text"
                    name="qboard_shared_links"
                    className="form-control form-control-sm my-3"
                    placeholder="Add your website link"
                  />
                  <div className="d-flex gap-2">
                    {/* <Field className="form-control form-control-md  my-3 display-category" name="qboard_category_id" as="select">
                      {props.categories.length && props.categories.map((option) => (
                        <option value={option.value} >{(option.value) ? option.label : 'select category'}</option>
                      ))}
                    </Field> */}
                    {props?.modalData?.activeTab == "My Connect" ? null : (
                      <div className="card flex justify-content-center w-50 form-control p-0">
                        <DropdownTreeSelect
                          name="qboard_category_id"
                          value={selectedNodeKey}
                          onChange={(e) =>
                            handleSelect(e.value, values, setFieldValue)
                          }
                          options={
                            props?.modalData?.activeTab === "My Interest"
                              ? myInterestData
                              : interestData
                          }
                          placeholder="Select post category"
                          className="md:w-100rem w-full"
                        />
                      </div>
                    )}
                    <Field
                      type="text"
                      name="qboard_temp_hashtag"
                      className="form-control form-control-sm my-3 "
                      placeholder="hashtag"
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          addHashTag(event.target.value, values, setFieldValue);
                        }
                      }}
                      onBlur={(event) => {
                        addHashTag(event.target.value, values, setFieldValue);
                      }}
                    />
                  </div>

                  <Field
                    type="hidden"
                    name="qboard_hashtag"
                    className="form-control form-control-sm mb-3"
                    value={
                      (values.qboard_hashtag = getValueKey(
                        values.qboard_hashtag_view,
                        "common_hashtag_tag",
                      ).join(","))
                    }
                  />

                  <div className="bootstrap-tagsinput">
                    {values.qboard_hashtag_view &&
                      values.qboard_hashtag_view.map((value, key) => (
                        <span className="tag label label-info d-inline-block">
                          {value.common_hashtag_tag}
                          <span
                            data-role="remove"
                            onClick={removeHashTag(key, values, setFieldValue)}
                          ></span>
                        </span>
                      ))}
                  </div>

                  <div className="qboard-file-details row">
                    {values.qboard_document_upload?.name ? (
                      <div className="d-flex">
                        <div className="file-details">
                          {values.qboard_document_upload.name}{" "}
                          <span
                            aria-hidden="true"
                            onClick={() =>
                              removefileData(
                                "qboard_document_upload",
                                values.qboard_document_upload
                                  .common_file_upload_id
                                  ? values.qboard_document_upload
                                      .common_file_upload_id
                                  : null,
                                setFieldValue,
                              )
                            }
                          >
                            ×
                          </span>
                        </div>
                        <Field
                          name="qboard_file_order"
                          className="form-control form-control-sm mb-3 display-order"
                          as="select"
                          onChange={(event) =>
                            orderChange(event, setFieldValue)
                          }
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </Field>
                      </div>
                    ) : null}
                    {values.qboard_image_upload?.name ? (
                      <div className="d-flex">
                        <div className="file-details">
                          {values.qboard_image_upload.name}{" "}
                          <span
                            aria-hidden="true"
                            onClick={() =>
                              removefileData(
                                "qboard_image_upload",
                                values.qboard_image_upload.common_file_upload_id
                                  ? values.qboard_image_upload
                                      .common_file_upload_id
                                  : null,
                                setFieldValue,
                              )
                            }
                          >
                            ×
                          </span>
                        </div>
                        <Field
                          name="qboard_file_order"
                          className="form-control form-control-sm mb-3 display-order"
                          as="select"
                          onChange={(event) =>
                            orderChange(event, setFieldValue)
                          }
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </Field>
                      </div>
                    ) : null}
                    {values.qboard_video_upload?.name ? (
                      <div className="d-flex">
                        <div className="file-details">
                          {values.qboard_video_upload.name}{" "}
                          <span
                            aria-hidden="true"
                            onClick={() =>
                              removefileData(
                                "qboard_video_upload",
                                values.qboard_video_upload.common_file_upload_id
                                  ? values.qboard_document_upload
                                      .common_file_upload_id
                                  : null,
                                setFieldValue,
                              )
                            }
                          >
                            ×
                          </span>
                        </div>
                        <Field
                          name="qboard_file_order"
                          className="form-control form-control-sm mb-3 display-order"
                          as="select"
                          onChange={(event) =>
                            orderChange(event, setFieldValue)
                          }
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </Field>
                      </div>
                    ) : null}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="posticons">
                        <div
                          className={
                            values.qboard_document_upload ||
                            values.qboard_image_upload ||
                            values.qboard_video_upload ||
                            values.qboard_youtube_link
                              ? "choose_file disable"
                              : "choose_file"
                          }
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/document.svg"
                            }
                          />
                          <input
                            name="qboard_document_upload"
                            type="file"
                            accept="application/*"
                            onChange={(event) => {
                              setFieldValue(
                                "qboard_document_upload",
                                event.currentTarget.files[0],
                              );
                            }}
                          />
                        </div>

                        <div
                          className={
                            values.qboard_document_upload ||
                            values.qboard_image_upload ||
                            values.qboard_video_upload ||
                            values.qboard_youtube_link
                              ? "choose_file disable"
                              : "choose_file"
                          }
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/img-icon.svg"
                            }
                          />
                          <input
                            name="qboard_image_upload"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              setFieldValue(
                                "qboard_image_upload",
                                event.currentTarget.files[0],
                              );
                            }}
                          />
                        </div>

                        <div
                          className={
                            values.qboard_document_upload ||
                            values.qboard_image_upload ||
                            values.qboard_video_upload ||
                            values.qboard_youtube_link
                              ? "choose_file disable"
                              : "choose_file"
                          }
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/icons/video.svg"
                            }
                          />
                          <input
                            name="qboard_video_upload"
                            type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            onChange={(event) => {
                              setFieldValue(
                                "qboard_video_upload",
                                event.currentTarget.files[0],
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col button">
                      <div className="d-flex  m-2">
                        <button
                          type="submit"
                          form="qboardpostmodal"
                          className={`btn  btn-block mx-2 btn-${props.activeColor}`}
                          disabled={isSubmitting}
                        >
                          {" "}
                          {isSubmitting ? (
                            <FormLoader />
                          ) : (
                            <span>{values.qboard_id ? "Update" : "Post"}</span>
                          )}
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-block"
                          onClick={closeModal}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  categories: state.common.categories,
  activeProfile: state.profile.activeProfile,
  keyTable: state.dashboardCommon.keyTable,
  modalData: state.dashboardCommon.modalContent,
  allInterests: state.profile.allInterests,
  myGroup: state.contact.grpdropdown,
  myInterest: state.profile.myInterest,
});

export default connect(mapStateToProps, {
  qboradPostData,
  setModalData,
  // getInterests,
})(QboardModal);
