import React from "react";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { toast } from "react-toastify";

const AppleLoginComponent = () => {
  const responseFacebookSuccess = (response) => {
    toast.success("Logged in with Facebook!");
  };

  const responseFacebookError = (response) => {
    toast.error("Please Try Again!");
  };

  return (
    <FacebookLogin
      appId="your-facebook-app-id" // Replace with your actual Facebook App ID
      autoLoad={false}
      fields="first_name,last_name,email"
      callback={responseFacebookSuccess}
      onFailure={responseFacebookError}
      render={(renderProps) => (
        <button
          className="btn d-flex align-content-around justify-content-center"
          onClick={renderProps.onClick}
          disabled={renderProps.isDisabled}
        >
          <span style={{ width: "18px", height: "18px", marginRight: "12px" }}>
            {/* <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/50px-Apple_logo_black.svg.png"
              alt="Facebook Icon"
              style={{ marginRight: "10px", verticalAlign: "middle" }}
            /> */}
            <FontAwesomeIcon
              icon={faApple}
              style={{
                fontSize: "20px", // Adjust the size of the icon
                color: "black", // Set a color if needed (e.g., Facebook blue)
              }}
            />
          </span>
          <div className="">Continue with Apple</div>
        </button>
      )}
    />
  );
};

export default AppleLoginComponent;
