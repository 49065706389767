import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { getUserRole } from "../../../../utils/UserDetails";

const FitConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  activeColor,
  link,
}) => {
  const history = useHistory();
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setIsModalOpen(false)}
      backdrop="static"
      keyboard={false}
      centered
      className="tj-modal"
    >
      <Modal.Header closeButton className="d-flex">
        <Modal.Title className="flex-grow-1 text-center">
          {link ? "Career Road Map" : " Are you good fit for role?"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {/* <div className="d-flex flex-column align-items-center py-1">
      <div className="rounded-circle bg-light d-flex align-items-center justify-content-center modal-check-success">
        <i className="fas fa-check-circle"></i>
      </div>
    </div> */}
        {link
          ? "Please update your profile to help us guide you on your career journey with a personalized roadmap."
          : "Please update your profile to allow us to assess your suitability for this role."}
      </Modal.Body>
      <Modal.Footer className=" justify-content-center ">
        <button
          type="button"
          className={`text-center btn btn-${activeColor} w-25 my-1`}
          onClick={() => {
            setIsModalOpen(false);
            if (link) {
              window.open(`/${getUserRole().toLowerCase()}/profile?id=1`);
            } else {
              history.push(`/${getUserRole().toLowerCase()}/profile`);
            }
          }}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, {})(FitConfirmationModal);
