import React, { useEffect } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import GlobalFilter from "./GlobalFilter";

const MasterTable = ({
  columns,
  data,
  checkBoxChecked,
  selectAllRef,
  alphabetData,
}) => {
  const pageSizeOptions = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: data?.length, label: "All" },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    footerGroups,
    setFilter,
    setHiddenColumns,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.accessor),
      },
      autoResetPage: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      // autoResetSelectedRows: false,
      // autoResetSelectedCell: false,
      // autoResetSelectedColumn: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      selectAllRef ? (
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div
                className="d-flex  justify-content-center align-content-center"
                // style={{
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "center",
                // }}
              >
                <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => {
              return (
                <div className="d-flex  justify-content-center align-content-center mt-2">
                  <input
                    type="checkbox"
                    ref={(el) => (selectAllRef.current[row?.original?.id] = el)}
                    value={row?.original?.id}
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              );
            },
          },
          ...columns,
        ])
      ) : (
        <></>
      );
    },
  );

  useEffect(() => {
    // set hidden column
    setHiddenColumns(
      columns.filter((col) => col.show === false).map((col) => col.accessor),
    );
  }, [setHiddenColumns, columns]);

  useEffect(() => {
    gotoPage(0);
  }, [alphabetData]);

  useEffect(() => {
    if (globalFilter) {
      gotoPage(0);
    }
  }, [globalFilter]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <span className="mx-2" />

        <span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="form-control"
          >
            {pageSizeOptions.map((pageSizes) => (
              <option value={pageSizes.value}>Show {pageSizes.label}</option>
            ))}
          </select>
        </span>
      </div>

      <div className="table-striped table-responsive">
        <table {...getTableProps()} className="r-table table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    key={`th_${columnIndex}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.render("Header") === "Remarks"
                        ? "text-center width-30"
                        : "text-center"
                    }
                  >
                    <div className="d-inine">
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="iconsminds-down" />
                          ) : (
                            <i className="iconsminds-up" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => (
                    <>
                      {cell.column.checkBox !== undefined ? (
                        <td style={{ verticalAlign: "middle" }}>
                          <input
                            type="checkbox"
                            value={JSON.stringify(row.original)}
                            onChange={(e) => checkBoxChecked(e)}
                          />
                        </td>
                      ) : (
                        <td
                          key={`td_${cellIndex}`}
                          {...cell.getCellProps({
                            className: cell.column.cellClass,
                          })}
                          style={{ verticalAlign: "middle" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      )}
                    </>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()} style={{ border: "white" }}>
                {group.headers.map((column) => (
                  <td
                    {...column.getFooterProps({
                      className: column.cellClass,
                    })}
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>

      <div className="react-table-pagination d-flex justify-content-between">
        <div className=""></div>
        <div className="">
          Page{" "}
          <strong>
            {pageOptions.length > 0 ? (
              <>
                {pageIndex + 1} of {pageOptions.length}
              </>
            ) : (
              <>
                {pageIndex + 1} of {pageOptions.length + 1}
              </>
            )}
          </strong>
        </div>

        <div className="pagination-icon">
          <i
            className="simple-icon-control-start"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
          <i
            className="simple-icon-arrow-left mx-2"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />

          {/* <>
            { pageIndex ? <span onClick={()=> gotoPage(pageIndex -1)}>{ pageIndex } </span> : null }
            <span  onClick={()=> gotoPage(pageIndex)}>{ pageIndex + 1 } </span>
            { pageIndex < (pageCount - 1) ?<span  onClick={()=> gotoPage(pageIndex + 1)}>{ pageIndex + 2 } </span> : null }
            </> */}
          <i
            className="simple-icon-arrow-right mx-2"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          />
          <i
            className="simple-icon-control-end"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </div>
      </div>
    </>
  );
};

export default MasterTable;
