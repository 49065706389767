import Axios from '../config/axios';

class ZoomService {

  getZoomConnect = async (zoomCode) => {
    return await Axios.post('/zoom-authentication', zoomCode).then((response) => {
      const { data } = response;
      return data;
      }).catch((error) => {
        console.log(error);
      });
	}

	setZoomScheduleMeeting = async (data) => {

		let zoomMeetingData = [];
		await Axios.post('/create-zoom-meeting', data).then((response) => {
			zoomMeetingData = response.data;
		})
		return zoomMeetingData;
	}

	getZoomMeetings = async () => {
		let zoomMeetingData = [];
		await Axios.get('/get-zoom-meetings', []).then((response) => {
			zoomMeetingData = response.data;
		})
		return zoomMeetingData;
	}

	deleteZoomMeeting = async (zoomdata) => {
		let zoomMeetingData = [];
		await Axios.post('/delete-zoom-meetings', zoomdata).then((response) => {
			zoomMeetingData = response.data;
		})
		return zoomMeetingData;
	};

}

const zoomServiceInstance = new ZoomService();
export default zoomServiceInstance;