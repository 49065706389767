import { useState, useEffect } from "react";

import { connect } from "react-redux";

import { getRequiredJobData } from "../../../../actions/job";
import { getUser } from "../../../../utils/UserDetails";
import Online from "./Online/Online";
import Offline from "./Offline/Offline";
import Classroom from "./Classroom/Classroom";

const TrainingMode = (props) => {
  const currentUser = getUser();
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (currentUser) props.getRequiredJobData();
  }, []);

  useEffect(() => {
    setActiveTab(props.activeTab);
  }, [props.activeTab]);

  return (
    <>
      {activeTab === "Online" && <Online searchQuery={props?.searchQuery} />}
      {/* {activeTab === "Offline" && <Offline />} */}
      {activeTab === "Classroom" && (
        <Classroom searchQuery={props?.searchQuery} />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { getRequiredJobData })(TrainingMode);
