import { useState, useEffect, Suspense } from "react";

import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroller";

import {
  getQboardPost,
  insertQboardPost,
  setQboardError,
  setQboardSuccess,
  updateQboardPost,
  getPostById,
} from "../../../../actions/qboard";
import { useParams } from "react-router-dom";
import QboardData from "./QboardData";
import QboardModal from "./QboardModal";
import Axios from "../../../../config/axios";
import PostModal from "./PostModal";
import { getUserRole } from "../../../../utils/UserDetails";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

toast.configure();

const Qboard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [showPostModal, setShowPostModal] = useState(true);

  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);

    if (props?.broadcastingEcho) {
      props.broadcastingEcho
        .private("qboard-chat")
        .listen("QboardPostEvent", (e) => {
          let postdata = e.postdata;
          if (postdata.isUpdate) {
            props.updateQboardPost(postdata);
          } else {
            props.insertQboardPost(postdata);
          }
        });

      setTimeout(function () {
        Axios.interceptors.request.use(function (config) {
          config.headers["X-Socket-ID"] = props.broadcastingEcho?.socketId()
            ? props.broadcastingEcho.socketId()
            : "";
          return config;
        });
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (props.mainFilter.tab_view) {
      setIsLoading(true);
      getPost(1);
    }
  }, [props.mainFilter]);

  useEffect(() => {
    if (props.qboardSuccess) {
      toast.success(props.qboardSuccess);
      props.setQboardSuccess("");
    }
  }, [props.qboardSuccess]);

  useEffect(() => {
    if (props.qboardError) {
      toast.error(props.qboardError);
      props.setQboardError("");
    }
  }, [props.qboardError]);

  useEffect(() => {
    if (
      props?.qboardPosts &&
      props.qboardPosts.length &&
      props.qboardPosts.length === props.qboardPostCount
    ) {
      setHasMoreItems(false);
    }
  }, [props.qboardPosts]);

  const getPost = (isFromFilter = 0, skip = 0) => {
    let qboardRequestData = { ...props.mainFilter };
    qboardRequestData.skip = skip;
    qboardRequestData.user_role = getUserRole();
    props.getQboardPost(qboardRequestData, setIsLoading, isFromFilter);
  };
  const handleClose = () => {
    history.push(`${getUserRole()?.toLowerCase()}/home`);
    setShowPostModal(false);
  };
  return (
    <>
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={50}
          width={50}
          className="loader text-center"
        />
      ) : (
        <>
          {props.qboardPosts && props.qboardPosts.length ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={() =>
                getPost(
                  0,
                  props.qboardPosts && props.qboardPosts.length
                    ? props.qboardPosts.length
                    : 0,
                )
              }
              hasMore={hasMoreItems}
              loader={
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={50}
                  width={50}
                  className="loader text-center"
                />
              }
              initialLoad={false}
            >
              {props.qboardPosts.map((post, postKey) => {
                return (
                  <>
                    <QboardData post={post} key={postKey} index={postKey} />
                  </>
                );
              })}
            </InfiniteScroll>
          ) : (
            <p className="text-center no-data-found my-2"> No Data Found</p>
          )}
        </>
      )}

      <QboardModal />
    </>
  );
};

const mapStateToProps = (state) => ({
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
  qboardPosts: state.qboard.qboardPost,
  qboardPostCount: state.qboard.qboardPostCount,
  mainFilter: state.dashboardCommon.mainFilter,
  broadcastingEcho: state.common.broadcastingEcho,
  qboardSuccess: state.qboard.qboardSuccess,
  qboardError: state.qboard.qboardError,
  postById: state.qboard.postById,
});

export default connect(mapStateToProps, {
  getQboardPost,
  insertQboardPost,
  updateQboardPost,
  setQboardError,
  setQboardSuccess,
  getPostById,
})(Qboard);
