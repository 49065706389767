import React, { useState } from "react";
import { Button, Card, Image, Modal } from "react-bootstrap";
const GroupNameModal = ({ showModal, handleClose, rowData, setShowModal }) => {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <>
      {" "}
      <Modal
        show={showModal}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        centered
        className="tj-modal"
      >
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1 text-center">
            Group List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-5">
          <div>
            {rowData.groups?.map((group, index) => (
              <div key={group.group_id} className="mb-2">
                <div
                  style={{
                    background: "#f1f1f1",
                    cursor: "pointer",
                    borderTop: "1px solid #ddd",
                  }}
                  className="p-1 px-3 d-flex align-items-center"
                >
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                      backgroundColor: "#007bff", // Change as needed
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "14px",
                      textTransform: "uppercase",
                    }}
                  >
                    {group.group_name?.charAt(0)}
                  </div>
                  <div className="px-3">
                    <b>{group.group_name}</b>
                  </div>
                </div>
                {openIndex === index && (
                  <div
                    style={{
                      padding: "10px",
                      border: "1px solid #ddd",
                      borderTop: "none",
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GroupNameModal;
