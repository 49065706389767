import { useState, useEffect } from "react";

import { Button, Modal } from "react-bootstrap";
import { batch, connect } from "react-redux";
import FormLoader from "../../../../components/FormLoader";
import { enrollCourse } from "../../../../actions/training";

const ConfirmModal = (props) => {
  const [showLoader, setShowLoader] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setShowLoader(false);
  }, []);

  const confirmEnroll = () => {
    if (!selectedBatch) {
      setError("Please select a batch before enroll"); // Set error if batch not selected
      return;
    }

    let params = {
      batch_id: selectedBatch,
      user_enroll_course_id: props.data.course_id,
    };
    props.enrollCourse(params, setShowLoader);
    setShowLoader(true);
  };

  const handleBatchChange = (event) => {
    setSelectedBatch(event.target.value);
    setError("");
  };

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      keyboard={false}
      centered
      className="tj-modal delete-modal"
    >
      <div className="text-end">
        <Button
          variant="close"
          className=" fa-2x "
          onClick={() => props.setShowModal(false)}
        />
      </div>
      <Modal.Header className="d-flex ">
        <Modal.Title className="flex-grow-1 text-center fs-5">
          Are you sure want to Enroll ?
        </Modal.Title>
      </Modal.Header>
      <div className="text-center px-5 mx-5">
        <p>Select the batch </p>
        <select
          className="form-select"
          value={selectedBatch}
          onChange={handleBatchChange}
        >
          <option value="" disabled>
            Select
          </option>

          {props?.data?.batches?.length ? (
            props?.data?.batches.map((batch, index) => (
              <option key={index} value={batch.coursebatch_id}>
                {batch.coursebatch_name} -{" "}
                {batch.language.teaching_languages_master_name} -{" "}
                {batch.learning_level.learning_levels_name}
              </option>
            ))
          ) : (
            <option disabled>No batches available</option>
          )}
        </select>
        <div style={{ height: 20 }}>
          {" "}
          {error && <p className="error-message small ">{error}</p>}
        </div>
      </div>
      <Modal.Footer className="px-0 mx-0 pb-3 mb-5 row w-100">
        <div className="d-flex justify-content-center gap-3">
          <button
            type="button"
            className={`btn mb-button mb-button-confirm btn-${props.activeColor}`}
            onClick={() => confirmEnroll()}
          >
            {showLoader ? <FormLoader /> : <>Yes</>}
          </button>
          <button
            type="button"
            className="btn mb-button mb-button-cancel"
            onClick={() => props.setShowModal(false)}
          >
            No
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, { enrollCourse })(ConfirmModal);
