import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getUser } from "../../../utils/UserDetails";
import { filterData } from "../../../actions/dashboradCommon";

// Searchable dropdown component for groups
const GroupSearchableDropdown = ({ options, value, onChange, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(placeholder);
  const dropdownRef = useRef(null);

  // Filter options based on search term
  const filteredOptions = options.filter((option) =>
    (option.group_name + "--" + option.user_name)
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  );

  // Handle selection of an item
  const handleSelect = (option) => {
    setSelectedLabel(`${option.group_name}--${option.user_name}`);
    setIsOpen(false);
    onChange({ target: { value: option.group_id.toString() } });
  };

  // Update selected label when value changes externally
  useEffect(() => {
    if (value) {
      const selectedOption = options.find(
        (opt) => opt.group_id.toString() === value,
      );
      if (selectedOption) {
        setSelectedLabel(
          `${selectedOption.group_name}--${selectedOption.user_name}`,
        );
      }
    } else {
      setSelectedLabel(placeholder);
    }
  }, [value, options, placeholder]);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="position-relative searchable-dropdown" ref={dropdownRef}>
      <div
        className="form-select form-select-sm filter-category-css d-flex justify-content-between align-items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className={value ? "" : "text-muted"}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          {selectedLabel}
        </span>
      </div>

      {isOpen && (
        <div
          className="dropdown-menu show w-100 position-absolute"
          style={{
            maxHeight: "300px",
            overflow: "auto",
            inset: "-24px 11px auto auto !important",
            zIndex: 1000,
          }}
        >
          <div className="px-2 pb-2">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search groups..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              autoFocus
            />
          </div>

          {filteredOptions.length === 0 ? (
            <div className="dropdown-item text-muted">No groups found</div>
          ) : (
            filteredOptions.map((option) => (
              <div
                key={option.group_id}
                className={`dropdown-item ${
                  option.group_id.toString() === value ? "active" : ""
                }`}
                onClick={() => handleSelect(option)}
              >
                {option.group_name}--{option.user_name}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

const Filter = (props) => {
  const currentUser = getUser();
  const starClass = currentUser
    ? props?.mainFilter && props?.mainFilter?.star_filter
      ? "fas fa fa-star fa-1x highlight-star"
      : "fas fa fa-star fa-1x"
    : "fas fa fa-star fa-1x invisible";

  const [interestData, setInterestData] = useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [myInterestData, setMyInterestData] = useState([]);

  useEffect(() => {
    filterSelect();
  }, [props.activeParentTab]);

  useEffect(() => {
    if (props.InterestsData) {
      let allInterestsData = props.InterestsData.map((item) => {
        return {
          key: item.interests_id,
          value: item.interests_id,
          label: item?.interests_name,
          selectable: false,
          children: item.children?.map((item1) => {
            return {
              key: item1.interests_id,
              value: item1.interests_id,
              label: item1.interests_name,
            };
          }),
        };
      });

      let data = {
        key: "",
        value: "",
        label: "Filter",
      };

      setInterestData([data, ...allInterestsData]);
    }
  }, [props.InterestsData]);

  useEffect(() => {
    if (props?.myInterest) {
      let allInterestsData = props?.myInterest.map((item) => {
        return {
          key: item.interests_id,
          value: item.interests_id,
          label: item?.interests_name,
          selectable: false,
          children: item.children?.map((item1) => {
            return {
              key: item1.interests_id,
              value: item1.interests_id,
              label: item1.interests_name,
            };
          }),
        };
      });

      let data = {
        key: "",
        value: "",
        label: "Filter",
      };

      setMyInterestData([data, ...allInterestsData]);
    } else {
      setMyInterestData([]);
    }
  }, [props?.myInterest, props?.activeTab]);

  function findChildInterest(interestsData, childId) {
    const allChildren = interestsData?.flatMap(
      (parent) => parent.children || [],
    );
    return allChildren?.find((child) => child.interests_id === childId) || null;
  }

  const filterSelect = (data) => {
    if (!data) return;

    const childInterest = findChildInterest(props.InterestsData, data?.value);
    let interestData = {
      name: childInterest?.interests_name,
      value: childInterest?.interests_id,
    };

    props?.setSelectedInterest(interestData);
    setSelectedNodeKey(data?.value);
    setfilterData(data?.value, 1);
  };

  const setfilterData = (value = "", isFromFilter = 0) => {
    let filterData = {};
    if (isFromFilter) {
      filterData.category_id = value;
    } else {
      filterData.star_filter = props.mainFilter.star_filter ? 0 : 1;
    }
    props.filterData(filterData);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    const selectedOption = props?.myGroup?.find(
      (opt) => opt.group_id.toString() === event.target.value,
    );
    props?.setSelectedGroup(selectedOption);

    let filterData = {};
    filterData.group_id = parseInt(event.target.value);
    props.filterData(filterData);
  };

  // Component for existing child-only, searchable interest dropdown
  const SearchableDropdown = ({ options, value, onChange, placeholder }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState(placeholder);
    const dropdownRef = useRef(null);

    // Flatten the nested structure to get only child items
    const flattenedOptions = options
      .flatMap((item) => {
        // Skip the first "Filter" item when flattening
        if (!item.children && item.key === "") return [];

        // Return only children
        return item.children ? item.children : [];
      })
      .filter(Boolean);

    // Filter options based on search term
    const filteredOptions = flattenedOptions.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    // Handle selection of an item
    const handleSelect = (option) => {
      setSelectedLabel(option.label);
      setIsOpen(false);
      onChange({ value: option.value });
    };

    // Update selected label when value changes externally
    useEffect(() => {
      if (value) {
        const selectedOption = flattenedOptions.find(
          (opt) => opt.value === value,
        );
        if (selectedOption) {
          setSelectedLabel(selectedOption.label);
        }
      } else {
        setSelectedLabel(placeholder);
      }
    }, [value, flattenedOptions, placeholder]);

    // Close dropdown when clicking outside
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div className="position-relative searchable-dropdown" ref={dropdownRef}>
        <div
          className="form-select form-select-sm filter-category-css d-flex justify-content-between align-items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span
            className={value ? "" : "text-muted"}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {selectedLabel}
          </span>
        </div>

        {isOpen && (
          <div
            className="dropdown-menu show w-100 position-absolute"
            style={{
              maxHeight: "300px",
              overflow: "auto",
              inset: "-24px 11px auto auto !important",
              zIndex: 1000,
            }}
          >
            <div className="px-2 pb-2">
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                autoFocus
              />
            </div>

            {filteredOptions.length === 0 ? (
              <div className="dropdown-item text-muted">No results found</div>
            ) : (
              filteredOptions.map((option) => (
                <div
                  key={option.key}
                  className={`dropdown-item ${
                    option.value === value ? "active" : ""
                  }`}
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="d-flex gap-2 my-1">
      <div>
        {props?.activeTab === "My Connect" ? (
          <GroupSearchableDropdown
            options={props?.myGroup || []}
            value={selectedValue}
            onChange={handleChange}
            placeholder="Select Your Group"
          />
        ) : (
          <SearchableDropdown
            value={selectedNodeKey}
            onChange={filterSelect}
            options={
              !currentUser
                ? []
                : props?.activeTab === "My Interest"
                ? myInterestData
                : interestData
            }
            placeholder="Filter"
          />
        )}
      </div>
      <div className="m-auto">
        <i className={starClass} onClick={() => setfilterData()}></i>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.common.categories,
  mainFilter: state.dashboardCommon.mainFilter,
});

export default connect(mapStateToProps, { filterData })(Filter);
