import { useState, useEffect } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import FileDetails from "../FileDetails";
import JobDetails from "../requirements/job/JobDetails";
import TrainingDetails from "../requirements/training/TrainingDetails";
import PostCardFooter from "./PostCardFooter";
import { getUserRole } from "../../../../utils/UserDetails";

const PostCardBody = (props) => {
  const [post, setPost] = useState();
  // const isFrom = 'qboard';
  const isFrom = props.isFrom;

  useEffect(() => {
    setPost(props.post);
  }, [props.post]);

  const getShareUrl = () => {
    let id;
    if (isFrom === "jobs") {
      id = post?.requirements_job_id;
    } else if (isFrom === "training") {
      id = post?.requirements_training_id;
    } else {
      id = post?.qboard_id;
    }
    return `${window.location.origin}/post/${id}/${isFrom}`;
  };

  const getShareTitle = () => {
    if (isFrom === "jobs") {
      return `Check out this job: ${post?.requirements_job_description || ""}`;
    } else if (isFrom === "training") {
      return `Check out this training: ${
        post?.requirements_training_description || ""
      }`;
    } else {
      return `${post?.qboard_description || ""}`;
    }
  };

  return (
    <>
      {post ? (
        <div className="card-body">
          {/* qboard description display before files*/}

          {props.isFrom === "qboard" &&
            post?.qboard_description &&
            post.qboard_description_order === 1 && (
              <div className="py-2">{post.qboard_description}</div>
            )}

          {/* Job description */}
          {isFrom === "jobs" && post?.requirements_job_description && (
            <div className="py-2">
              <div className="center-sec">
                {(() => {
                  try {
                    const descriptionArray =
                      typeof post.requirements_job_description === "string"
                        ? JSON.parse(post.requirements_job_description)
                        : post.requirements_job_description;

                    if (Array.isArray(descriptionArray)) {
                      return descriptionArray.map((obj, index) => (
                        <div key={index}>
                          <p className="heading">
                            {obj.criterion_name + ":"}
                            <span>
                              {obj.criterion_value.length < 30
                                ? " " + obj.criterion_value
                                : ""}
                            </span>
                          </p>
                          {obj.criterion_value.length >= 30 && (
                            <p className="content">{obj.criterion_value}</p>
                          )}
                        </div>
                      ));
                    } else {
                      return <p className="content">{descriptionArray}</p>;
                    }
                  } catch (error) {
                    console.error("Error parsing JSON:", error);
                    return (
                      <p className="content">Error loading job description.</p>
                    );
                  }
                })()}
              </div>
            </div>
          )}

          {/* training description */}
          {props.isFrom === "training" &&
            post?.requirements_training_description && (
              <div className="py-2">
                {post.requirements_training_description}
                <TrainingDetails post={post} />
              </div>
            )}

          {/* website link */}
          {post?.common_urls?.common_url_link && (
            <Link
              to={{ pathname: post.common_urls.common_url_link }}
              target="_blank"
            >
              {post?.common_urls?.common_url_link}
            </Link>
          )}

          {/* hastag */}
          {post.common_hash_tag_value.length !== 0 && (
            <div className="row py-2">
              <div className="tags">
                {post.common_hash_tag_value.map((value, key) => (
                  <span>
                    {value.split(" ").map(function (char, index) {
                      return (
                        <span
                          className="badge bg-dark-light text-primary"
                          key={index}
                        >
                          {char}
                        </span>
                      );
                    })}
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* file details */}
          <FileDetails commonFiles={post.common_files} />

          {/* qboard description display after files*/}
          {props.isFrom === "qboard" &&
            post.qboard_description &&
            post.qboard_description_order === 2 && (
              <div className="py-2">{post.qboard_description}</div>
            )}

          {/* {(props.isFrom === 'qboard') && */}
          <PostCardFooter
            post={post}
            key={props.index}
            index={props.index}
            isFrom={isFrom}
            getShareUrl={getShareUrl}
            getShareTitle={getShareTitle}
          />
          {/* } */}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps)(PostCardBody);
