import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import Player from "../../../components/Player";

const FileDetails = (props) => {
  const [commonFiles, setCommonFiles] = useState([]);

  useEffect(() => {
    setCommonFiles(props.commonFiles);
  }, [props.commonFiles]);

  return (
    <div>
      {commonFiles && commonFiles?.length
        ? commonFiles.map((value) => {
            let fileUrl = value.common_file_is_youtube
              ? value.common_file_upload_server_url
              : process.env.REACT_APP_BACKEND_BASEURL +
                "/storage" +
                value.common_file_upload_server_url;

            let fileType = value.common_file_upload_material_type.split("/");

            if (fileType.includes("image")) {
              return (
                <div className="p-3">
                  <img src={fileUrl} width="100%" height="500" />
                </div>
              );
            }

            if (fileType.includes("application")) {
              let fileName = value.common_file_upload_server_url
                .split("/")
                .pop();
              return (
                <div className="px-4 file-download">
                  <Link
                    to={{ pathname: fileUrl }}
                    target="_blank"
                    className="p-2"
                  >
                    {fileName}
                  </Link>
                  <FontAwesomeIcon
                    icon={faFileDownload}
                    className="text-muted"
                  />
                </div>
              );
            }

            if (fileType.includes("video")) {
              return (
                <div
                  className={
                    `md-2 ` +
                    (value.common_file_is_youtube ? "youtube-video" : "")
                  }
                >
                  <Player fileUrl={fileUrl} />
                </div>
              );
            }
          })
        : null}
    </div>
  );
};

export default FileDetails;
