import { useState, useEffect } from "react";

import { connect } from "react-redux";

import Jobs from "./job/Jobs";
import Training from "./training/Training";
import { getRequiredJobData } from "../../../../actions/job";
import { getUser } from "../../../../utils/UserDetails";

const Requirements = (props) => {
  const currentUser = getUser();
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (currentUser) props.getRequiredJobData();
  }, []);

  useEffect(() => {
    setActiveTab(props.activeTab);
  }, [props.activeTab]);

  return (
    <>
      {activeTab === "Jobs" && <Jobs jobPosts={props?.jobPosts} />}
      {activeTab === "Training" && (
        <Training trainingPosts={props?.trainingPosts} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  jobPosts: state.job.jobPost,
  trainingPosts: state.reqTraining.trainingPost,
});

export default connect(mapStateToProps, { getRequiredJobData })(Requirements);
