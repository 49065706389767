import { useState, useEffect } from "react";
import CommentFooter from "./CommentFooter";
import CommentHeader from "./CommentHeader";

const CommentView = (props) => {
  const [post, setPost] = useState();
  const [comments, setComments] = useState([]);
  const [showcomment, setShowComment] = useState(false);

  useEffect(() => {
    setPost(props.post);
  }, [props.post]);

  return (
    <>
      {post ? (
        <div className="position-relative ">
          <div className="comment-data p-0  ">
            <div className="post-box content  px-2 pt-2 my-0 ">
              <div
                className="primary-bg-color p-1 m-1"
                style={{ borderRadius: "10px" }}
              >
                <CommentHeader post={post} />
                <p className=" describe-details mb-0 pb-1">
                  {post.common_comments_description}
                </p>
              </div>
              <CommentFooter
                post={post}
                setComments={setComments}
                setShowComment={setShowComment}
                showcomment={showcomment}
                comments={comments}
              />
            </div>
          </div>
        </div>
      ) : null}

      {/* comment view  */}
      {comments.length && showcomment ? (
        <div className="post-comment-view mb-1">
          {comments.map((cmts, postKeys) => {
            return (
              <CommentView
                post={cmts}
                key={postKeys}
                setComments={setComments}
                setShowComment={setShowComment}
                showcomment={showcomment}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default CommentView;
