import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { TreeSelect } from 'primereact/treeselect';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";

const DropdownSelect = ({
  value,
  options,
  optionLabel,
  optionGroupLabel,
  optionGroupChildren,
  optionGroupTemplate,
  onChange,
  placeholder
}) => {

  const handleChange = (val) => {
    onChange(val);
  };

  return (
    <Dropdown
      value={value}
      options={options}
      optionLabel={optionLabel}
      optionGroupLabel={optionGroupLabel}
      optionGroupChildren={optionGroupChildren}
      optionGroupTemplate={optionGroupTemplate}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};

const DropdownTreeSelect = ({
  name,
  value,
  options,
  onChange,
  placeholder,
  className
}) => {

  const handleChange = (val) => {
    onChange(val);
  };

  return (
    <TreeSelect
      name={name}
      value={value}
      onChange={handleChange}
      options={options}
      className={className}
      placeholder={placeholder}
    >
    </TreeSelect>
  );
};

export {
  DropdownSelect,
  DropdownTreeSelect
};
