import React, { useState, useEffect, Suspense } from "react";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import Filter from "./Filter";
import Qboard from "./qboard/Qboard";
import Requirements from "./requirements/Requirements";
import { getUser, getUserMenus, getUserRole } from "../../../utils/UserDetails";
import ProfileService from "../../../services/profile.service";
import {
  filterData,
  setModalData,
  setActiveKeyTable,
  resetFilterData,
} from "../../../actions/dashboradCommon";
// import {
//   QboardMenus,
//   RequirementMenus,
//   TrainingMenus,
// } from "../../../constants/Menu";
import CommonService from "../../../services/common.service";
import { getInterests, getMyInterests } from "../../../actions/profile";
import TrainingMode from "./TrainingMode/TrainingMode";
import { getGroupDropdown } from "../../../actions/contact";
import { toast } from "react-toastify";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import PostModal from "./qboard/PostModal";
import { getPostById } from "../../../actions/qboard";

const DeleteModal = React.lazy(() => import("./DeleteModal"));
const CommentModal = React.lazy(() => import("./CommentModal"));
const JobModal = React.lazy(() => import("./requirements/job/JobModal"));
const TrainingModal = React.lazy(() =>
  import("./requirements/training/TrainingModal"),
);

const FeedTabContent = (props) => {
  const currentUser = getUser();
  const [activeTab, setActiveTab] = useState(null);
  const [tabHeaders, setTabHeaders] = useState([]);
  const [postPlaceholder, setPostPlaceholder] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedInterest, setSelectedInterest] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // Search Query State

  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const postJD = queryParams.get("post");
  const [showPostModal, setShowPostModal] = useState(true);
  const { pstid, tabview } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (pstid) {
      setShowPostModal(true);
      let params = { id: pstid, tab_view: tabview ? tabview : "" };

      props?.getPostById(params);
    } else {
      setShowPostModal(false);
    }
  }, [pstid]);

  const QboardMenus = [
    getUserMenus()?.some(
      (menu) => menu.functionality?.functionality_master_name === "Global",
    )
      ? {
          label: "qboard-global",
          name: "Global",
        }
      : undefined,
    getUserMenus()?.some(
      (menu) => menu.functionality?.functionality_master_name === "My Interest",
    )
      ? {
          label: "qboard-myinterest",
          name: "My Interest",
        }
      : undefined,

    getUserMenus()?.some(
      (menu) => menu.functionality?.functionality_master_name === "My Connect",
    )
      ? {
          label: "qboard-myconnect",
          name: "My Connect",
        }
      : undefined,
  ];

  const RequirementMenus = [
    getUserMenus()?.some(
      (menu) => menu.functionality?.functionality_master_name === "Jobs",
    )
      ? {
          label: "requirements-jobs",
          name: "Jobs",
        }
      : undefined,
    getUserMenus()?.some(
      (menu) => menu.functionality?.functionality_master_name === "Training",
    )
      ? {
          label: "requirements-training",
          name: "Training",
        }
      : undefined,
  ].filter(Boolean);

  const TrainingMenus = [
    getUserMenus()?.some(
      (menu) => menu.functionality?.functionality_master_name === "Online",
    )
      ? {
          label: "Online",
          name: "Online",
        }
      : undefined,
    // getUserMenus()?.some(
    //   (menu) => menu.functionality?.functionality_master_name === "Offline",
    // )
    //   ? {
    //       label: "Offline",
    //       name: "Offline",
    //     }
    //   : undefined,
    getUserMenus()?.some(
      (menu) => menu.functionality?.functionality_master_name === "Classroom",
    )
      ? {
          label: "Classroom",
          name: "Classroom",
        }
      : undefined,
  ].filter(Boolean);

  useEffect(() => {
    props.getInterests();
    props?.getGroupDropdown();
    let param = { user_role: getUserRole() };
    props?.getMyInterests(param);
  }, []);

  useEffect(() => {
    // let tabs = QboardMenus;
    // if (
    //   props.activeParentTab &&
    //   props.activeParentTab.toLowerCase() === "requirements"
    // ) {
    //   tabs = RequirementMenus;
    // } else if (
    //   props.activeParentTab &&
    //   props.activeParentTab.toLowerCase() === "training"
    // ) {
    //   tabs = TrainingMenus;
    // }
    // tabs = tabs.filter((tab) => tab !== undefined);

    const topMenuData = getUserMenus()?.filter(
      (menu) => menu.module?.module_order === "Top menu",
    );

    const activeModule = topMenuData.find(
      (menu) => menu.module?.module_name === props.activeParentTab,
    );

    const subModules = activeModule ? activeModule.submodules : [];

    const tabs = subModules.map((sub) => ({
      label: sub.sub_module_name,
      name: sub.sub_module_name,
    }));

    setTabHeaders(tabs);
    setActiveTab(tabs[0]?.label);
  }, [props.activeParentTab]);

  useEffect(() => {
    if (
      props.activeParentTab &&
      props.activeParentTab.toLowerCase() === "qboard"
    ) {
      let tabFilter = { ...props.mainFilter };
      tabFilter.tab_view = activeTab;
      props.filterData(tabFilter);
      setPostPlaceholder("Post your queries ...");
      props.setActiveKeyTable(1);
    } else if (
      props.activeParentTab &&
      props.activeParentTab.toLowerCase() === "training"
    ) {
      let tabFilter = { ...props.mainFilter };
      tabFilter.tab_view = activeTab;
      props.filterData(tabFilter);
    } else {
      if (activeTab === "Jobs") {
        setPostPlaceholder("Post your job requirements ...");
        props.setActiveKeyTable(3);
      } else {
        setPostPlaceholder("Post your training requirements ...");
        props.setActiveKeyTable(2);
      }
      props.resetFilterData();
    }
    CommonService.scrollToTop();
  }, [activeTab]);

  const openModal = () => {
    let modalData = { ...props.modalContent };
    if (props.activeParentTab.toLowerCase() === "qboard") {
      if (activeTab === "My Connect" && props.myGroup.length === 0) {
        toast.error("Please create a  group");
        setTimeout(() => {
          window.open(`/${getUserRole()?.toLowerCase()}/contact`);
        }, 3000);
      } else {
        props.setModalData({
          ...modalData,
          qboardModal: true,
          qboardObj: {},
          activeTab: activeTab,
          selectedGroup: selectedGroup,
          selectedInterest:
            activeTab == "Global"
              ? selectedInterest
              : findChildInterest(props?.myInterest, selectedInterest?.value) ==
                null
              ? ""
              : selectedInterest,
        });
      }
    } else {
      if (activeTab === "Jobs") {
        props.setModalData({
          ...modalData,
          requirementJobModal: true,
          jobObj: {},
        });
      } else {
        props.setModalData({
          ...modalData,
          requirementTrainingModal: true,
          trainingObj: {},
        });
      }
    }
  };

  const handleClose = () => {
    history.push(`${getUserRole()?.toLowerCase()}/home`);
    setShowPostModal(false);
  };

  function findChildInterest(interestsData, childId) {
    const allChildren = interestsData?.flatMap(
      (parent) => parent.children || [],
    );
    return allChildren?.find((child) => child.interests_id === childId) || null;
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <div className="sticky-center">
        <div className={`post-details-headers ${props.activeColor}`}>
          <div className="qboard-header my-2 align-items-center">
            <ul className="qboard-header2-css p-0">
              {tabHeaders.map((tab) => {
                return (
                  <div
                    key={tab?.label}
                    className={
                      activeTab === tab?.label
                        ? "font-weight-bold margin-right-css"
                        : "margin-right-css"
                    }
                    onClick={() =>
                      currentUser || props.activeParentTab === "Requirements"
                        ? setActiveTab(tab?.label)
                        : null
                    }
                  >
                    {tab?.name}
                  </div>
                );
              })}
            </ul>
            <div>
              <Filter
                activeParentTab={props.activeParentTab}
                activeTab={activeTab}
                setSelectedGroup={setSelectedGroup}
                setSelectedInterest={setSelectedInterest}
                myGroup={props?.myGroup}
                InterestsData={props.allInterests}
                myInterest={props?.myInterest}
              />
            </div>
          </div>
        </div>

        {props.activeParentTab !== "Training" ? (
          <div className="post-query-input d-flex">
            <img
              className="profile-icon rounded-circle "
              src={
                props.activeProfile &&
                props.activeProfile?.user_profile_image_url
                  ? props.activeProfile?.user_profile_image_url
                  : ProfileService.defaultProfilePic()
              }
              alt="profile-logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  process.env.PUBLIC_URL + "/images/icons/profile1.jpg";
              }}
            />
            <input
              type="text"
              placeholder={postPlaceholder}
              className="form-control primary-bg-color"
              onClick={currentUser && openModal}
              readOnly
              disabled={
                (getUserRole() === "STUDENT" || getUserRole() === "TRAINER") &&
                activeTab === "Jobs"
                  ? true
                  : false
              }
            />
          </div>
        ) : (
          <div className="post-query-input d-flex">
            <i className="fa fa-search my-auto font-size-20px" />
            {/* <img
              className="profile-icon rounded-circle "
              src={
                props.activeProfile &&
                props.activeProfile?.user_profile_image_url
                  ? props.activeProfile?.user_profile_image_url
                  : ProfileService.defaultProfilePic()
              }
              alt="profile-logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  process.env.PUBLIC_URL + "/images/icons/profile1.jpg";
              }}
            /> */}
            <input
              type="text"
              placeholder="Search Courses..."
              className="form-control primary-bg-color"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        )}
      </div>

      <div className="post-details-content">
        <div className="post-queries-data">
          {props.activeParentTab &&
            props.activeParentTab.toLowerCase() === "qboard" && <Qboard />}
          {props.activeParentTab &&
            props.activeParentTab.toLowerCase() === "requirements" && (
              <Suspense fallback={""}>
                <Requirements activeTab={activeTab} />
              </Suspense>
            )}
          {props.activeParentTab &&
            props.activeParentTab.toLowerCase() === "training" && (
              <Suspense fallback={""}>
                <TrainingMode activeTab={activeTab} searchQuery={searchQuery} />
              </Suspense>
            )}
        </div>
      </div>

      {/* modal view */}
      <Suspense fallback={""}>
        <CommentModal />
      </Suspense>

      <Suspense fallback={""}>
        <DeleteModal />
      </Suspense>

      <Suspense fallback={""}>
        <TrainingModal />
      </Suspense>

      <Suspense fallback={""}>
        <JobModal />
      </Suspense>
      <Suspense>
        <PostModal
          show={showPostModal}
          onHide={handleClose}
          post={props.postById}
          postKey={0}
          tabview={tabview}
        />
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
  mainFilter: state.dashboardCommon.mainFilter,
  modalContent: state.dashboardCommon.modalContent,
  allInterests: state.profile.allInterests,
  myGroup: state.contact.grpdropdown,
  myInterest: state.profile.myInterest,
  postById: state.qboard.postById,
});

export default connect(mapStateToProps, {
  filterData,
  setActiveKeyTable,
  resetFilterData,
  setModalData,
  getInterests,
  getMyInterests,
  getGroupDropdown,
  getPostById,
})(FeedTabContent);
