import { Spinner } from "react-bootstrap";

const CommanSpinner = ({ color }) => {
  return (
    <div>
      <Spinner animation="border" variant={color ? color : "white"} size="sm" />
    </div>
  );
};

export default CommanSpinner;
