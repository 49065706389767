import Emojify from "react-emojione";

const Smilies = (props) => {
  return (
    <div
      className={
        props.isChildren ? "children show-more-smilies" : "show-more-smilies"
      }
    >
      <Emojify onClick={(e) => props.setSmilies(e.target.title)}>
        <span> :thumbsup: :heart: :clap: ^__^ :( </span>
      </Emojify>
    </div>
  );
};

export default Smilies;
