import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";

const AddContactModal = ({
  showModal,
  handleClose,
  rowData,
  handleAddContact,
  setShowModal,
}) => {
  const initialValues = {
    name: rowData?.name ? rowData?.name : "",
    email: rowData?.email ? rowData?.email : "",
    mobile: rowData?.phone ? rowData.phone.replace(/^\+/, "") : "",
    location: rowData?.location ? rowData?.location : "",
  };

  const phoneValidation = (value) => {
    if (!value) {
      return "Mobile number is required";
    }
    if (!value.startsWith("+")) {
      value = `+${value}`;
    }
    const phoneNumber = parsePhoneNumberFromString(value);
    if (!phoneNumber || !isValidPhoneNumber(value)) {
      return "Invalid phone number";
    }
    return true;
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile: Yup.string()
      .test(
        "is-valid-phone",
        "Invalid phone number",
        (value) => phoneValidation(value) === true,
      )
      .required("Mobile number is required"),
  });

  return (
    <>
      {" "}
      <Modal
        show={showModal}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        centered
        className="tj-modal"
      >
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1 text-center">
            Add Contact
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(
              formData,
              { setSubmitting, resetForm, setFieldValue },
            ) => {
              handleAddContact(
                formData,
                resetForm,
                setSubmitting,
                setFieldValue,
              );
            }}
          >
            {({ errors, touched, isSubmitting, setFieldValue, values }) => (
              <Form>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    <strong className="text-muted"> Name</strong>
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter name"
                  />
                  <div className="err-height">
                    <span className="error-message">
                      {errors.name && touched.name ? errors.name : null}
                    </span>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    <strong className="text-muted"> Email</strong>
                  </label>
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter email"
                  />
                  <div className="err-height">
                    <span className="error-message">
                      {rowData?.invite
                        ? "Please enter an email address to invite"
                        : errors.email && touched.email
                        ? errors.email
                        : null}
                    </span>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="mobile" className="form-label">
                    <strong className="text-muted">Mobile Number</strong>
                  </label>
                  <PhoneInput
                    country={"in"} // Set default country
                    value={values.mobile}
                    onChange={(value) => setFieldValue("mobile", value)}
                    inputClass="form-control"
                  />
                  <div className="err-height">
                    <span className="error-message">
                      {errors.mobile && touched.mobile ? errors.mobile : null}
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="location" className="form-label">
                    <strong className="text-muted">Location</strong>
                  </label>
                  <Field
                    type="text"
                    name="location"
                    className="form-control"
                    placeholder="Enter location"
                  />
                  <div className="err-height">
                    {/* <span className="error-message">
                      {errors.email && touched.email ? errors.email : null}
                    </span> */}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12 text-end">
                    <Button
                      variant="primary"
                      className="me-2 "
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddContactModal;
