import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";

import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { fitForRole } from "../../../actions/carreerProfile";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FitRoleModal = ({
  show,
  handleClose,
  userProfiles,
  activeProfile,
  activeColor,
  fitForRole,
  setShow,
  fitRole,
}) => {
  const baseUrl = window.location.origin;
  const history = useHistory();
  const [selectedProfile, setSelectedProfile] = useState();
  const [profiles, setProfiles] = useState([]);
  const [profileUrl, setProfileUrl] = useState(`${baseUrl}/student/preview/`);
  const [fileError, setFileError] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [uploadDisable, setUploadDisable] = useState(false);
  const [state, setState] = useState(false);
  const [jdFile, setJdFile] = useState(null);
  const [jdFileName, setJdFileName] = useState("");
  const [jdText, setJdText] = useState("");
  const [JDError, setJDError] = useState("");
  const [jdContent, setJdContent] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fitRole) {
      history.push("/student/fit-for-role", {
        jdContent,
        profileUrl,
        jdText,
        profileData,
      });
      setShow(false);
    }
  }, [fitRole]);

  useEffect(() => {
    setProfileUrl(
      `${baseUrl}/student/preview/${activeProfile?.user_profile_url}`,
    );
    setSelectedProfile(activeProfile?.user_profile_url);

    if (
      (!activeProfile?.job_experience?.length > 0 ||
        !activeProfile?.qualification?.length > 0 ||
        !activeProfile?.certificates?.length > 0) &&
      activeProfile?.user_profile_url
    ) {
      setFileError("Please update the selected profile details");
    }
  }, [activeProfile]);

  useEffect(() => {
    const mappedProfiles = userProfiles.map((profile, index) => ({
      name: profile.user_profile_name,
      value: profile.user_profile_url,
    }));
    setProfiles(mappedProfiles);
  }, [userProfiles]);

  const profileData = userProfiles.filter((profile) => {
    return profile.user_profile_url == selectedProfile; // Ensure you return a boolean value
  });

  const handleProfileChange = (e) => {
    const newProfileId = e.target.value;

    const haveProfileDetails = userProfiles.filter((profile) => {
      return profile.user_profile_url == e.target.value; // Ensure you return a boolean value
    });

    if (
      haveProfileDetails[0]?.job_experience?.length > 0 ||
      haveProfileDetails[0]?.qualification?.length > 0 ||
      haveProfileDetails[0]?.certificates?.length > 0
    ) {
      setFileError("");
    } else {
      setFileError("Please update your profile details");
    }
    setSelectedProfile(newProfileId);
    setProfileUrl(`${baseUrl}/student/preview/${newProfileId}`);

    const timer = setTimeout(() => {
      setState(!state);
    }, [2000]);

    return () => clearTimeout(timer);
  };

  const handleJDChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/plain") {
      const reader = new FileReader();

      reader.onload = (event) => {
        setJdContent(event.target.result);
      };

      reader.readAsText(file);
    }

    if (file) {
      setJdFile(file);
      setJdFileName(file.name);
      setIsDisable(true);
      setJDError("");
    } else {
      setJdFileName("");
      setIsDisable(false);
    }
  };

  const handleText = (e) => {
    const value = e.target.value;
    setJdText(value); // Update the text first

    if (value) {
      setUploadDisable(true);
      setJDError("");
    } else {
      setUploadDisable(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!profileData?.length) {
      setFileError("Please upload your profile");
      setLoading(false);
    }

    if (!jdFile && !jdText) {
      setJDError("Please upload your job description");
      setLoading(false);
    }

    if (profileData?.length && (jdFile || jdText) && fileError == "") {
      let formData = new FormData();
      formData.append("profile_id", profileData[0]?.user_profile_id);
      formData.append("profile_data", JSON.stringify(profileData[0]));
      formData.append("user_id", profileData[0]?.user_profile_userid);

      if (jdFile) {
        formData.append("file", jdFile);
      } else if (jdText) {
        formData.append("jd_text", jdText);
      }

      fitForRole(formData, setLoading);
    }
  };

  const hideModal = () => {
    setJdFileName("");
    setJdText("");
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => hideModal()}
      centered
      size="xl"
      className="tj-modal"
    >
      <Modal.Header closeButton className="d-flex">
        <Modal.Title className="flex-grow-1 text-center">
          {" "}
          Are you a good fit for this role? (AI)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="px-3 pb-3">
          {/* First Half: Profile Dropdown & Iframe */}

          <Col md={6}>
            <Form.Group controlId="profileSelect">
              <Form.Label>
                <b>Select Profile</b>
              </Form.Label>
              <Form.Control
                as="select"
                value={selectedProfile}
                onChange={handleProfileChange}
                style={{ appearance: "auto" }}
                disabled={profiles.length <= 1}
              >
                <option value="">Choose...</option>
                {profiles.map((profile, idx) => (
                  <option key={idx} value={profile.value}>
                    {profile.name}
                  </option>
                ))}
              </Form.Control>
              <div className="text-muted filename-display">
                <span className="error-message">
                  {" "}
                  {fileError ? fileError : ""}
                </span>
              </div>
            </Form.Group>

            {selectedProfile ? (
              <>
                <Form.Group controlId="textInput">
                  <Form.Label className="mt-2">
                    {/* <b>Job Description</b> */}
                    <b className="pt-1"> Copy and paste the JD below</b>
                  </Form.Label>
                  <iframe
                    src={profileUrl}
                    title="Profile Preview"
                    width="100%"
                    height="300px"
                    style={{ border: "1px solid #ddd" }}
                  />{" "}
                </Form.Group>
              </>
            ) : (
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={"300px"}
                width={50}
                className="loader"
              />
            )}
          </Col>

          <Col md={6}>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="fileUpload" className="mb-2">
                <Form.Label className="w-100">
                  <b>Upload JD</b>
                </Form.Label>
                {/* <Form.Control
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                /> */}
                <label
                  className={`btn btn-md p-4 px-4  fit-btn prfl-details-none ${
                    uploadDisable ? "disabled-btn" : `btn-${activeColor}`
                  }`}
                  disabled={uploadDisable}
                >
                  <FontAwesomeIcon
                    icon={faUpload}
                    className="text-white"
                    style={{ marginRight: "8px" }}
                  />
                  <b> Choose File </b>
                  <input
                    type="file"
                    accept=".txt"
                    name="jdUpload"
                    className="d-none"
                    onChange={(e) => handleJDChange(e)}
                    disabled={uploadDisable}
                  />{" "}
                </label>
                <span className="mx-5 h5">OR</span>
                <p className="mb-0">(*.txt)</p>
                <div className="text-muted filename-display mt-0">
                  {jdFileName ? jdFileName : ""}
                  <span className="error-message">
                    {" "}
                    {JDError ? JDError : ""}
                  </span>
                </div>
              </Form.Group>

              <Form.Group controlId="textInput">
                <Form.Label>
                  {/* <b>Job Description</b> */}
                  <b> Copy and paste the JD below</b>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5} // Initial rows height
                  style={{ resize: "none", overflow: "hidden", height: 300 }} // Disable manual resize and hide overflow
                  // placeholder="Enter Job Description "
                  value={jdText}
                  maxLength={3000} // Maximum 3000 characters
                  onChange={(e) => {
                    handleText(e);
                  }}
                  disabled={isDisable}
                />
                <Form.Text muted>{jdText.length} / 3000 characters</Form.Text>
              </Form.Group>
              <div className="text-end">
                <Button
                  type="submit"
                  className={`mb-3 px-4 btn-${activeColor}`}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  userProfiles: state.profile.userProfiles,
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
  fitRole: state.roadmap.fitForRole,
});

export default connect(mapStateToProps, { fitForRole })(FitRoleModal);
