import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../components/FormLoader";
import { connect } from "react-redux";
import { createGroup } from "../../../actions/contact";

const ContactGroupModal = ({
  toggleModal,
  modalData,
  createGroup,
  setActiveMenu,
}) => {
  const initialValues = {
    groupname: "",
    groupdescription: "",
  };

  const validationSchema = Yup.object().shape({
    groupname: Yup.string().required("Group name is required"),
    groupdescription: Yup.string().required("Description is required"),
  });

  const handleAddGroup = (
    formData,
    resetForm,
    setSubmitting,
    setFieldValue,
  ) => {
    let params = {
      group_name: formData.groupname,
      group_description: formData.groupdescription,
      members: modalData?.data,
    };
    createGroup(params, resetForm, setSubmitting, setFieldValue, setActiveMenu);
    toggleModal();
  };

  return (
    <>
      <Modal
        show={modalData.modalOpen}
        onHide={toggleModal}
        backdrop="static"
        // size={"lg"}
        centered
        className="tj-modal"
      >
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1 text-center">
            {modalData.header}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(
              formData,
              { setSubmitting, resetForm, setFieldValue },
            ) => {
              handleAddGroup(formData, resetForm, setSubmitting, setFieldValue);
              // createGroup(formData, resetForm, setSubmitting, setFieldValue);
            }}
          >
            {(formProps) => {
              const {
                values,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                errors,
                touched,
              } = formProps;

              return (
                <Form>
                  <div className="form-group mb-1">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        <strong className="text-muted">Group Name</strong>
                      </label>
                      <Field
                        type="text"
                        name="groupname"
                        id="groupname"
                        className="form-control form-control-sm"
                        placeholder="Group Name"
                      />
                      <div className="err-height">
                        <span className="error-message">
                          {errors.groupname && touched.groupname
                            ? errors.groupname
                            : null}
                        </span>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        <strong className="text-muted">
                          {" "}
                          Group Description
                        </strong>
                      </label>
                      <Field
                        type="text"
                        name="groupdescription"
                        id="groupdescription"
                        className="form-control form-control-sm"
                        placeholder="Group Description"
                      />
                      <div className="err-height">
                        <span className="error-message">
                          {errors.groupdescription && touched.groupdescription
                            ? errors.groupdescription
                            : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-auto mx-auto">
                      <button
                        type="submit"
                        className="text-center profile-gradient-btn btn btn-secondary btn-block "
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <FormLoader />
                        ) : (
                          <span>{"Create"}</span>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { createGroup })(ContactGroupModal);
