import Axios from "../config/axios.js";
class ContactService {
  addContact = async (formdata) => {
    const url = formdata?.contact_id
      ? formdata?.name
        ? "/update-contact" // Update if contact_id exists and name is provided
        : "/delete-contact" // Delete if contact_id exists but no name is provided
      : "/add-contact"; // Add if contact_id is empty
    return Axios.post(url, formdata)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getContact = async (formdata) => {
    return Axios.get("/get-contacts", { params: formdata })
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createGroup = async (formdata) => {
    const url = formdata?.group_id
      ? formdata?.group_name
        ? "/update-contact" // Update if contact_id exists and name is provided
        : "/delete-group" // Delete if contact_id exists but no name is provided
      : "/add-group"; // Add if contact_id is empty
    return Axios.post(url, formdata)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getGroup = async (formdata) => {
    return Axios.get("/get-groups", { params: formdata })
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getMyGroup = async (formdata) => {
    return Axios.get("/get-my-group", { params: formdata })
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getGrpContact = async (formdata) => {
    return Axios.get("/get-contacts-not-in-group", { params: formdata })
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  storeContact = async (formdata) => {
    return Axios.post("/store-contact", formdata)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  addMember = async (formdata) => {
    return Axios.post("/add-member", formdata)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  removeMember = async (formdata) => {
    return Axios.post("/remove-member", formdata)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getGroupDropdown = async () => {
    return Axios.get("/get-all-groups")
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  InviteMember = async (formdata) => {
    return Axios.post("/invite-email", formdata)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
const ContactServiceInstance = new ContactService();
export default ContactServiceInstance;
