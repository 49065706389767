import {
  SET_QBOARD_POST,
  QBOARD_POST_COUNT,
  UPDATE_QBOARD,
  QBOARD_ERROR,
  INSERT_QBOARD,
  QBOARD_SUCCESS,
  DELETE_QBOARD_POST,
  GETPOSTBYID,
} from "../actions/types";

const initialState = {
  qboardPost: [],
  qboardPostCount: 0,
  qboardError: "",
  qboardSuccess: "",
  postById: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_QBOARD_POST:
      return {
        ...state,
        qboardPost: payload.isCreation
          ? payload.qboardPost
          : state.qboardPost.concat(payload.qboardPost),
      };

    case INSERT_QBOARD:
      return {
        ...state,
        qboardPost: payload.qboard_parent_id
          ? state.qboardPost.map((post) =>
              post.qboard_id === payload.qboard_parent_id
                ? { ...post, parent: [payload, ...post.parent] }
                : post,
            )
          : [payload, ...state.qboardPost],
      };

    case QBOARD_POST_COUNT:
      return { ...state, qboardPostCount: payload };

    case GETPOSTBYID:
      return { ...state, postById: payload };

    case UPDATE_QBOARD:
      return {
        ...state,
        qboardPost: payload.qboard_parent_id
          ? state.qboardPost.map((post) =>
              post.qboard_id === payload.qboard_parent_id
                ? {
                    ...post,
                    parent: post.parent.map((posts) =>
                      posts.qboard_id === payload.qboard_id
                        ? { ...posts, ...payload }
                        : posts,
                    ),
                  }
                : post,
            )
          : state.qboardPost.map((post) =>
              post.qboard_id === payload.qboard_id
                ? { ...post, ...payload }
                : post,
            ),
        postById: payload.qboard_parent_id
          ? state.qboardPost.map((post) =>
              post.qboard_id === payload.qboard_parent_id
                ? {
                    ...post,
                    parent: post.parent.map((posts) =>
                      posts.qboard_id === payload.qboard_id
                        ? { ...posts, ...payload }
                        : posts,
                    ),
                  }
                : post,
            )
          : state.qboardPost.map((post) =>
              post.qboard_id === payload.qboard_id
                ? { ...post, ...payload }
                : post,
            ),
      };

    case DELETE_QBOARD_POST:
      return {
        ...state,
        qboardPost: payload.qboard_parent_id
          ? state.qboardPost.map((post) =>
              post.qboard_id === payload.qboard_parent_id
                ? {
                    ...post,
                    parent: post.parent.filter(
                      (posts) => posts.qboard_id !== payload.qboard_id,
                    ),
                  }
                : post,
            )
          : state.qboardPost.filter(
              (post) => post.qboard_id !== payload.qboard_id,
            ),
      };

    case QBOARD_SUCCESS:
      return { ...state, qboardSuccess: payload };

    case QBOARD_ERROR:
      return { ...state, qboardError: payload };

    default:
      return state;
  }
};

export default reducer;
