import {
  ACTIVE_KEY_TABLE,
  FILTER_DATA,
  RESET_FILTER_DATA,
  DASH_COMMON_ERROR,
  COMMENT_POST_DATA,
  MODAL_DATA,
  DELETE_POST_DATA,
} from "./types";
import QboardService from "../services/qboard.service";
import { updateQboardPost } from "./qboard";
import { updateJobPost } from "./job";
import { updateTrainingPost } from "./reqTraining";
import { toast } from "react-toastify";

export const setSmilies = (smilies) => async (dispatch) => {
  let smiliesData;

  if (smilies.qboard_id) {
    smiliesData = {
      qboard_id: smilies.post_id,
      qboard_parent_id: smilies.qboard_parent_id,
    };
  } else if (smilies.requirements_job_id) {
    smiliesData = {
      requirements_job_id: smilies.requirements_job_id,
    };
  } else {
    smiliesData = {
      requirements_training_id: smilies.requirements_training_id,
    };
  }

  try {
    let resultData = await QboardService.qboardPostLike(smilies);

    if (resultData && !resultData.errCode) {
      smiliesData = { ...smiliesData, ...resultData.updatePost };
      if (smilies.qboard_id) {
        dispatch(updateQboardPost(smiliesData));
      } else if (smilies.requirements_job_id) {
        dispatch(updateJobPost(smiliesData));
      } else {
        dispatch(updateTrainingPost(smiliesData));
      }
    } else {
      dispatch(setCommonError("Please Try Again"));
    }
  } catch (err) {
    dispatch(setCommonError("Please Try Again"));
  }
};

export const getComments = (commentData) => async (dispatch) => {
  try {
    if (commentData.showComment) {
      let resultData = await QboardService.getComments(commentData);

      if (resultData && !resultData.errCode) {
        commentData.common_comments = resultData.comments;
        if (commentData.qboard_id) {
          dispatch(updateQboardPost(commentData));
        } else if (commentData.requirements_job_id) {
          dispatch(updateJobPost(commentData));
        } else {
          dispatch(updateTrainingPost(commentData));
        }
      } else {
        dispatch(setCommonError("Please Try Again"));
      }
    } else {
      if (commentData.qboard_id) {
        dispatch(updateQboardPost(commentData));
      } else if (commentData.requirements_job_id) {
        dispatch(updateJobPost(commentData));
      } else {
        dispatch(updateTrainingPost(commentData));
      }
    }
  } catch (err) {
    dispatch(setCommonError("Please Try Again"));
  }
};

export const onFavouriteChange = (favouriteData) => async (dispatch) => {
  try {
    const resultData = await QboardService.addFavourite(favouriteData);

    if (resultData?.status && resultData.status === true) {
      favouriteData.favourite = resultData.favourite;
      if (favouriteData.qboard_id) {
        dispatch(updateQboardPost(favouriteData));
      } else if (favouriteData.requirements_job_id) {
        dispatch(updateJobPost(favouriteData));
      } else {
        dispatch(updateTrainingPost(favouriteData));
      }
    } else {
      dispatch(setCommonError("Please Try Again"));
    }
  } catch (err) {
    dispatch(setCommonError("Please Try Again"));
  }
};

export const commentPostData =
  (commentData, setSubmitting, closeCommentModal) => async (dispatch) => {
    try {
      const resultData = await QboardService.qboardCommentUpdate(commentData);

      if (!resultData?.errCode) {
        commentData.common_comments = resultData.data;
        commentData.showComment = 1;
        if (commentData.qboard_id) {
          dispatch(updateQboardPost(commentData));
        } else if (commentData.requirements_job_id) {
          dispatch(updateJobPost(commentData));
        } else {
          dispatch(updateTrainingPost(commentData));
        }
        setSubmitting(false);
        closeCommentModal();
      } else {
        setSubmitting(false);
        dispatch(setCommonError("Please Try Again"));
      }
    } catch (err) {
      setSubmitting(false);
      dispatch(setCommonError("Please Try Again"));
    }
  };

export const reportSpam = (setBtnLoad, data) => async (dispatch) => {
  try {
    const res = await QboardService.reportSpam(data);
    if (res.status === true) {
      toast.success("Reported Spam successfully");
    } else {
      toast.error("Please try again");
    }
    console.log(res);
    if (setBtnLoad) {
      setBtnLoad(false);
    }
  } catch (err) {
    console.log(err);
  }
};

export const setCommentData = (commentData) => {
  return {
    type: COMMENT_POST_DATA,
    payload: commentData,
  };
};

export const setModalData = (modalData) => {
  return {
    type: MODAL_DATA,
    payload: modalData,
  };
};

export const setDeletePostData = (deletePostData) => {
  return {
    type: DELETE_POST_DATA,
    payload: deletePostData,
  };
};

export const setActiveKeyTable = (keyTable = 1) => {
  return {
    type: ACTIVE_KEY_TABLE,
    payload: keyTable,
  };
};

export const filterData = (filterData) => {
  return {
    type: FILTER_DATA,
    payload: filterData,
  };
};
export const resetFilterData = () => {
  return {
    type: RESET_FILTER_DATA,
    payload: "",
  };
};

export const setCommonError = (messages = "") => {
  return {
    type: DASH_COMMON_ERROR,
    payload: messages,
  };
};
