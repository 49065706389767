const AdsMenu = () => {
  return (
    <>
      <div className="ads-view">
        <img
          src={process.env.PUBLIC_URL + "/images/icons/online-3412473_1920.jpg"}
          alt="ads"
        />
      </div>
      <div className="ads-view">
        <img
          src={process.env.PUBLIC_URL + "/images/icons/online-3412498_1920.jpg"}
          alt="ads"
        />
      </div>
    </>
  );
};

export default AdsMenu;
