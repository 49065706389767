import React from "react";

import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import {
  setJobApplyDetails,
  jobApplyresumeDownload,
} from "../../../../../actions/job";
import MasterTable from "../../../../../components/MasterTable";

const JobApplyDetailsModal = (props) => {
  const closeModal = () => {
    let jobApplyData = { ...props.jobApplyDetails };
    props.setJobApplyDetails({ ...jobApplyData, isModalOpen: false });
  };

  const downloadResume = (jobApplyID) => {
    props.jobApplyresumeDownload(jobApplyID);
  };

  const col = React.useMemo(
    () => [
      {
        Header: "S No",
        accessor: "sno",
        cellClass: "text-center",
        Cell: (props) => <>{props?.row?.index + 1}</>,
        sortType: "basic",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Applied Date",
        accessor: "created_at",
      },
      {
        Header: "Download",
        accessor: "",
        Cell: ({ row }) => (
          <i
            className="fa fa-download text-secondary mx-4"
            aria-hidden="true"
            onClick={() => downloadResume(row.requirements_job_apply_id)}
          ></i>
        ),
      },
    ],

    [],
  );

  return (
    <Modal
      show={props.jobApplyDetails.isModalOpen}
      size={"lg"}
      className={`tj-modal ` + (props.darkTheme ? "is-dark-theme" : "")}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="d-flex">
        <Modal.Title className="flex-grow-1">Applied Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MasterTable
          data={
            props.jobApplyDetails.appliedDetailsData?.length
              ? props.jobApplyDetails.appliedDetailsData
              : []
          }
          columns={col}
        />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  jobApplyDetails: state.job.jobApplyDetails,
});

export default connect(mapStateToProps, {
  setJobApplyDetails,
  jobApplyresumeDownload,
})(JobApplyDetailsModal);
