import React, { useState, useEffect, Suspense } from "react";

import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { BiHeart, BiPlus } from "react-icons/bi";
import {
  BsJournalText,
  BsCameraVideo,
  BsPersonWorkspace,
  BsBriefcase,
  BsBook,
  BsCloud,
  BsPersonCheck,
  BsCircle,
  BsClipboardCheck,
  BsMortarboard,
} from "react-icons/bs";

import CustomUserLinkModal from "./CustomUserLinkModal";
import CommonIcons from "../../../../components/CommonIcons";
import {
  getUser,
  getUserMenus,
  getUserRole,
} from "../../../../utils/UserDetails";
import {
  getCustomUserLink,
  setCustomUserLinkModal,
} from "../../../../actions/customUserLink";
import {
  setTrainingApplyDetails,
  AppliedDetails,
  RecruiterAppliedDetails,
  uploadCv,
} from "../../../../actions/reqTraining";
import { feedSidebarIsCollapsed } from "../../../../actions/common";
import { getZoomConnect } from "../../../../actions/zoomMeeting";
import TrainingApplyDetailsModal from "./TrainingApplyDetails";
import { setModalData } from "../../../../actions/dashboradCommon";
import { getProfileDetails } from "../../../../actions/profile";
import FitRoleModal from "../../Fitforrole/FitRoleModal";
import FitConfirmationModal from "./FitConfirmationModal";

// import TrainingCourse from './TrainingCourse';

const CustomUserLink = React.lazy(() => import("./CustomUserLink"));
const ZoomMeeting = React.lazy(() => import("./ZoomMeeting/ZoomMeeting"));
const TrainingCourse = React.lazy(() =>
  import("./TrainingCourse/TrainingCourse"),
);

const FeedLeftMenu = (props) => {
  const currentUser = getUser();
  const history = useHistory();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const zoomRedirectCode = queryParams.get("code");

  const [isExpanded, setIsExpanded] = useState(null);
  const [show, setShow] = useState(false);
  const [collapseHover, setCollapseHover] = useState(null);
  const [menuHover, setMenuHover] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [customUserMenu, setCustomUserMenu] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [showCourse, setShowCourse] = useState(false);
  const [showCv, setShowCv] = useState(false);
  const [showZoom, setShowZoom] = useState(true);
  const [isMyFavOpen, setIsMyFavOpen] = useState(true);
  const [isMyPageOpen, setIsMyPageOpen] = useState(true);
  const [isVidOpen, setIsVidOpen] = useState(true);
  const [isMyTrainingOpen, setIsMyTrainingOpen] = useState(true);
  const [isMyJobsOpen, setIsMyJobsOpen] = useState(true);
  const [isMyLearningsOpen, setIsMyLearningsOpen] = useState(true);
  const [isMyCareerOpen, setIsMyCareerOpen] = useState(true);
  const [isMySpaceOpen, setIsMySpaceOpen] = useState(true);
  const [isMyRecruitOpen, setIsMyRecruitOpen] = useState(true);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [haveProfile, setHaveProfile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [link, setLink] = useState("");
  const [sideMenuData, setSideMenuData] = useState([]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      if (event.data.haveProfile == true) {
        const timer = setTimeout(() => {
          setHaveProfile(true);
          props.getProfileDetails({ getall: 1 });
        }, 2000);
        return () => clearTimeout(timer);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (
      props?.activeProfile?.job_experience?.length > 0 ||
      props?.activeProfile?.qualification?.length > 0 ||
      props?.activeProfile?.certificates?.length > 0
    ) {
      setHaveProfile(true);
    } else {
      setHaveProfile(false);
    }
  }, [props?.activeProfile]);

  useEffect(() => {
    if (currentUser) {
      props.getCustomUserLink();
    }
    setIsExpanded(true);
    setCollapseHover(false);
    setMenuHover(false);

    if (getUserMenus()?.length > 0) {
      const sideMenus = getUserMenus()?.filter(
        (item) => item.module.module_order === "Side menu",
      );
      setSideMenuData(sideMenus);
    }
  }, []);

  useEffect(() => {
    setCustomUserMenu(props.customUserLink);
  }, [props.customUserLink]);

  useEffect(() => {
    if (zoomRedirectCode) {
      queryParams.delete("code");
      queryParams.delete("state");
      history.replace({
        search: queryParams.toString(),
      });

      props.getZoomConnect({ code: zoomRedirectCode });
      setTimeout(() => {
        if (window.opener && window.name === "tjunctionZoomAuth") {
          window.self.close();
        }
      }, 1000);
    }
  }, [zoomRedirectCode]);

  useEffect(() => {
    props.feedSidebarIsCollapsed(isExpanded ? "is-expand" : "");
  }, [isExpanded]);

  const handleFileChange = (e) => {
    e.stopPropagation();
    setFile(e.target.files[0]);
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!file) {
      setError("Please upload a file."); // Set error message if no file
      return;
    }
    props.uploadCv({ profileId: 45, user_id: 12, resume: file }); //setShowCv(false); // Close the upload form
  };

  const onClick = () => {
    if (showResults) {
      setShowResults(false);
    } else {
      setShowResults(true);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const onClickCourse = () => {
    if (showCourse) {
      setShowCourse(false);
    } else {
      setShowCourse(true);
    }
  };

  const onClickCv = (e) => {
    e.stopPropagation();
    setError("");
    setShowCv((prev) => !prev); // Toggle the visibility of the CV upload form
  };

  const onClickZoom = () => {
    if (showZoom) {
      setShowZoom(false);
    } else {
      setShowZoom(true);
    }
  };

  const handleToggler = () => {
    setCollapseHover(false);
    setActiveMenu("");
    if (isExpanded) {
      setIsExpanded(false);
      return;
    }
    setIsExpanded(true);
  };

  const openLinkModal = (customUserLinkObj = {}) => {
    let modalData = { ...props.customUserLinkModal };
    props.setCustomUserLinkModal({
      ...modalData,
      isModalOpen: true,
      customUserLinkObj: { ...customUserLinkObj },
    });
  };

  const handleRoadmap = () => {
    if (haveProfile) {
      window.open(`/${getUserRole().toLowerCase()}/roadmap`);
    } else {
      setLink(true);
      setIsModalOpen(true);
    }
  };
  const handleFitRole = () => {
    if (haveProfile) {
      // window.open(`/${getUserRole().toLowerCase()}/fit-for-role`);

      setShow(true);
    } else {
      // window.open(`/${getUserRole().toLowerCase()}/profile?fitid=1`);
      setLink(false);
      setIsModalOpen(true);
    }
  };

  const enhanceJDModal = () => {
    let modalData = { ...props.modalContent };
    props.setModalData({
      ...modalData,
      requirementJobModal: true,
      jobObj: {},
    });
  };

  const openModal = (appliedDetailsData = {}) => {
    // let modalContent = { ...props.modalContent };
    // props.setTrainingApplyDetails({
    //   ...modalContent,
    //   isModalOpen: true,
    //   appliedDetailsData: { ...appliedDetailsData },
    // });
    if (getUserRole() == "STUDENT" || getUserRole() == "TRAINER") {
      props.AppliedDetails({ type: 1 });
    } else if (getUserRole() == "RECRUITER") {
      props.RecruiterAppliedDetails({ type: 1 });
    }
  };

  const formStyle = {
    marginTop: "10px", // Adjust to move the submit link down
  };

  const linkStyle = {
    display: "inline-block",
    color: "#007bff", // Blue color for the link
    textDecoration: "none",
    fontWeight: "bold",
    cursor: "pointer",
    marginTop: "10px",
    fontSize: "12px", // Additional margin if needed
  };

  const linkHoverStyle = {
    textDecoration: "underline",
  };

  const containerStyle = {
    marginTop: "10px", // Adjust to move the submit link down
  };

  const initialExpandedState = getUserMenus()?.reduce((acc, menu) => {
    acc[menu.module.module_id] = true;
    return acc;
  }, {});

  const [expandedMenus, setExpandedMenus] = useState(initialExpandedState);

  const toggleMenu = (moduleId) => {
    setExpandedMenus((prev) => ({
      ...prev,
      [moduleId]: !prev[moduleId],
    }));
  };

  const handleMenuClick = (data) => {
    const subModuleName = data?.sub_module_name?.replace(/\u00A0/g, " ");

    if (subModuleName === "My Profile") {
      history.push(`/${getUserRole().toLowerCase()}/profile`);
    } else if (subModuleName === "My Job") {
      history.push(`/${getUserRole().toLowerCase()}/myjob`);
    } else if (subModuleName === "Create Training Curriculum (Ai)") {
      window.open(" http://trainer.tjunction.co.in:8501/", "_blank");
    } else if (subModuleName === "Create Training Content (Ai)") {
      window.open(" http://trainer.tjunction.co.in:8501/", "_blank");
    } else if (subModuleName === "Manage Training") {
      history.push(`/${getUserRole().toLowerCase()}/training`);
    } else if (subModuleName === "Training Enquiries") {
      history.push(`/${getUserRole().toLowerCase()}/enquiries`);
    } else if (subModuleName === "Applied") {
      history.push(`/${getUserRole().toLowerCase()}/appliedjob`);
    } else if (subModuleName === "Posted") {
      history.push(`/${getUserRole().toLowerCase()}/postedjob`);
    } else if (subModuleName === "My Courses") {
      history.push(`/${getUserRole().toLowerCase()}/enroll`);
    } else if (subModuleName === "Career Road Map (AI)") {
      handleRoadmap();
    } else if (subModuleName === "Are you a good fit for this role? (AI)") {
      handleFitRole();
    } else if (subModuleName === "Create and Enhance JD (AI)") {
      enhanceJDModal();
    } else if (
      subModuleName === "Sort Resume Based on Job Roles / Skills (AI)"
    ) {
      window.open(`/${getUserRole().toLowerCase()}/sort-resume`);
    } else if (subModuleName === "Resume Ranking Based on JD (AI)") {
      window.open("/recruiter/resume-ranking", "_blank");
    } else if (subModuleName === "Google Drive") {
      window.open("https://drive.google.com", "_blank");
    } else if (subModuleName === "One Drive") {
      window.open("https://onedrive.live.com/", "_blank");
    } else if (subModuleName === "Zoom") {
      onClickZoom();
    } else if (subModuleName === "Google Meet") {
      window.open("https://meet.google.com/", "_blank");
    } else if (subModuleName === "ZointVC") {
      window.open("https://zointvc.com/", "_blank");
    } else {
      history.push(`/${getUserRole().toLowerCase()}/home`);
      console.log("error");
    }
  };

  return (
    <>
      <div
        className={isExpanded ? "qboard-sidebar" : "qboard-sidebar collapsed"}
      >
        <div className="sidebar-items">
          <div
            className={`collapse-left d-none d-lg-block mx-2`}
            onClick={handleToggler}
            onMouseEnter={() => setCollapseHover(true)}
            onMouseLeave={() => setCollapseHover(false)}
          // style={{ marginRight: "8px" }}
          >
            {isExpanded ? (
              <CommonIcons collapse={1} collapseHover={collapseHover} />
            ) : (
              <CommonIcons expand={1} collapseHover={collapseHover} />
            )}
          </div>
          <div className="overflow-auto">
            {sideMenuData?.map((menu) => {
              const { module, submodules } = menu;
              return (
                <>
                  {/* Main Menu Item */}

                  <div
                    id={props.activeColor}
                    className={`item mb-1 ${expandedMenus[module.module_id]
                      ? `${props.activeColor} active`
                      : ""
                      }`}
                    onClick={() => toggleMenu(module.module_id)}
                  >
                    {module.module_name === "My Learning" ? (
                      <BsBook style={{ margin: isExpanded ? "" : "auto" }} />
                    ) : module.module_name === "Video Conferencing" ? (
                      <BsCameraVideo
                        style={{ margin: isExpanded ? "" : "auto" }}
                      />
                    ) : module.module_name === "My Career" ? (
                      <BsBriefcase
                        style={{ margin: isExpanded ? "" : "auto" }}
                      />
                    ) : module.module_name === "My Jobs" ? (
                      <BsPersonWorkspace
                        style={{ margin: isExpanded ? "" : "auto" }}
                      />
                    ) : module.module_name === "My Requirement" ? (
                      <BsClipboardCheck
                        style={{ margin: isExpanded ? "" : "auto" }}
                      />
                    ) : module.module_name === "My  Training" ? (
                      <BsMortarboard
                        style={{ margin: isExpanded ? "" : "auto" }}
                      />
                    ) : (
                      <BsJournalText
                        style={{ margin: isExpanded ? "" : "auto" }}
                      />
                    )}
                    <span className="sidebar-text">{module.module_name}</span>
                    <span
                      className={`fas fa sidebar-arrow-down ${expandedMenus[module.module_id]
                        ? "fa-angle-left"
                        : "fa-angle-down"
                        }`}
                    ></span>
                  </div>

                  {/* Submenu Items */}

                  {expandedMenus[module.module_id] &&
                    submodules.map((sub) => (
                      <div
                        key={sub.sub_module_id}
                        className="item sub-item"
                        onClick={() => handleMenuClick(sub)}
                      >
                        <span
                          className="sidebar-text"
                          id={`${props.activeColor}` + `-insidezoom`}
                        >
                          {sub.sub_module_name}
                        </span>
                        {sub.sub_module_name === "Zoom" ?
                        <span
                        style={{paddingTop:'4px'}}
                          className={`fas fa sidebar-arrow-down ${showZoom
                            ? "fa fa-caret-up"
                            : "fa fa-caret-down"
                            }`}
                        ></span>
                        : null }
                        {sub.sub_module_name === "Zoom" ?
                          showZoom ? <ZoomMeeting /> : null : null}
                      </div>
                    ))}
                </>
              );
            })}
          </div>
        </div>
      </div>
      <Suspense>
        <CustomUserLinkModal />
      </Suspense>
      <Suspense>
        <FitRoleModal
          show={show}
          setShow={setShow}
          handleClose={handleClose}
        ></FitRoleModal>
      </Suspense>
      <Suspense>
        <FitConfirmationModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          link={link}
        />
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  customUserLink: state.customUserLink.customUserLink,
  customUserLinkModal: state.customUserLink.customUserLinkModal,
  cvData: state.customUserLink.cvData,
  activePopupWindow: state.common.activePopupWindow,
  userProfiles: state.profile.userProfiles,
  modalContent: state.dashboardCommon.modalContent,
  activeProfile: state.profile.activeProfile,
});

export default connect(mapStateToProps, {
  getCustomUserLink,
  setCustomUserLinkModal,
  feedSidebarIsCollapsed,
  getZoomConnect,
  setTrainingApplyDetails,
  AppliedDetails,
  RecruiterAppliedDetails,
  uploadCv,
  setModalData,
  getProfileDetails,
})(FeedLeftMenu);
