import QboardService from "../services/qboard.service";
import {
  SET_QBOARD_POST,
  QBOARD_POST_COUNT,
  UPDATE_QBOARD,
  QBOARD_ERROR,
  INSERT_QBOARD,
  QBOARD_SUCCESS,
  DELETE_QBOARD_POST,
  GETPOSTBYID,
} from "./types";

export const getQboardPost =
  (qboardRequestData, setIsLoading, isFromFilter) => async (dispatch) => {
    try {
      let qboardData = {
        isCreation: isFromFilter,
      };
      let qboardPostCount = 0;

      const resultData = await QboardService.getQboardPosts(qboardRequestData);

      if (resultData.posts && resultData.posts.status === true) {
        if (setIsLoading) {
          setIsLoading(false);
        }
        qboardData.qboardPost = resultData.posts.collection;
        qboardPostCount = resultData.posts.post_count;
      }

      dispatch({
        type: SET_QBOARD_POST,
        payload: qboardData,
      });

      dispatch({
        type: QBOARD_POST_COUNT,
        payload: qboardPostCount,
      });
    } catch (err) {
      dispatch(setQboardError("Please Try Again!"));
    }
  };

export const getPostById = (data) => async (dispatch) => {
  try {
    const res = await QboardService.getPostById(data);

    dispatch({
      type: GETPOSTBYID,
      payload: res?.post ? res?.post : [],
    });
  } catch (err) {
    console.log(err);
  }
};
export const qboradPostData =
  (formData, resetForm, setSubmitting, closeModal) => async (dispatch) => {
    let notifyMsg = "Posted";
    console.log(formData);
    try {
      const resultData = await QboardService.postQboardData(formData);
      if (resultData.errCode === 0) {
        closeModal();
        resetForm();

        if (formData.qboard_id) {
          notifyMsg = "Updated";
          dispatch(updateQboardPost(resultData.data));
        } else {
          dispatch(insertQboardPost(resultData.data));
        }

        if (formData.qboard_parent_id) {
          dispatch(
            updateQboardPost({
              qboard_id: formData.qboard_parent_id,
              showAnswer: 1,
            }),
          );
        }

        dispatch(setQboardSuccess(notifyMsg));
      } else {
        setSubmitting(false);
        dispatch(setQboardError("Please Try Again!"));
      }
    } catch (err) {
      setSubmitting(false);
      dispatch(setQboardError("Please Try Again!"));
    }
  };

export const updateQboardPost = (updatedData) => {
  return {
    type: UPDATE_QBOARD,
    payload: updatedData,
  };
};

export const insertQboardPost = (updatedData) => {
  return {
    type: INSERT_QBOARD,
    payload: updatedData,
  };
};

export const deleteQboardPost =
  (deletePostData, setShowDeleteLoader, closeConfirmationModal) =>
  async (dispatch) => {
    try {
      const resultData = await QboardService.deleteQboardPost(
        deletePostData.qboard_id,
      );

      if (resultData.errCode === 0) {
        setShowDeleteLoader(false);
        closeConfirmationModal();
        dispatch(setQboardSuccess("Deleted"));
        dispatch({
          type: DELETE_QBOARD_POST,
          payload: deletePostData,
        });
      }
    } catch (err) {
      setShowDeleteLoader(false);
      dispatch(setQboardError("Please Try Again!"));
    }
  };

export const setQboardSuccess = (messages = "") => {
  return {
    type: QBOARD_SUCCESS,
    payload: messages,
  };
};

export const setQboardError = (messages = "") => {
  return {
    type: QBOARD_ERROR,
    payload: messages,
  };
};
