import React from "react";
import { useAsyncDebounce } from "react-table";

// import { useIntl } from "react-intl";

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((values) => {
    setGlobalFilter(values || undefined);
  }, 200);
  //   const intl = useIntl();
  return (
    <span>
      <input
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search..."
      />
    </span>
  );
};

export default GlobalFilter;
