import { useEffect, useRef } from "react";

import { Formik, Form, Field } from "formik";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as Yup from "yup";

import FormLoader from "../../../../../components/FormLoader";
import {
  applyTraining,
  setTrainingApplyData,
} from "../../../../../actions/reqTraining";

const TrainingApplyModal = (props) => {
  const formikRef = useRef();

  const trainingApplyFormValues = {
    ...props.trainingApply,
    requirements_training_apply_term: false,
  };

  const trainingApplyValidationSchema = Yup.object().shape({
    requirements_training_apply_term: Yup.bool().oneOf(
      [true],
      "Accept Terms & Conditions is required",
    ),
  });

  useEffect(() => {
    if (props.trainingApply.isModalOpen) formikRef.current?.resetForm();
  }, [props.trainingApply.isModalOpen]);

  const closeModal = () => {
    let trainingApplyData = { ...props.trainingApply };
    props.setTrainingApplyData({ ...trainingApplyData, isModalOpen: false });
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={trainingApplyFormValues}
        validationSchema={trainingApplyValidationSchema}
        validateOnChange={false}
        onSubmit={(formData, { resetForm, setSubmitting }) => {
          props.applyTraining(formData, resetForm, setSubmitting, closeModal);
        }}
      >
        {(formProps) => {
          const { values, isSubmitting, errors, touched, setFieldValue } =
            formProps;

          return (
            <Form id="trainingapplymodal">
              <Modal
                show={props.trainingApply.isModalOpen}
                size={"lg"}
                className={
                  `tj-modal ` + (props.darkTheme ? "is-dark-theme" : "")
                }
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Header closeButton className="d-flex">
                  <Modal.Title className="flex-grow-1">
                    Apply for the training
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="container" style={{ padding: "10px 0px" }}>
                    <div className="col">
                      {values.requirements_training_apply_description}
                    </div>

                    {values.requirements_training_apply_no_of_days ? (
                      <div className="form-group row">
                        <label className="col-sm col-form-label">
                          <b> No.of Days </b>
                        </label>
                        <div className="col-sm mt-2">
                          {values.requirements_training_apply_no_of_days}
                        </div>
                      </div>
                    ) : null}

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm col-form-label">
                            <b> From Date</b>
                          </label>
                          <div className="col-sm mt-2">
                            {values.requirements_training_apply_startdate}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm col-form-label">
                            <b>To Date</b>
                          </label>
                          <div className="col-sm mt-2">
                            {values.requirements_training_apply_enddate}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pull-right">
                      <Field
                        className="form-check-input"
                        type="checkbox"
                        name="requirements_training_apply_term"
                        checked={
                          parseInt(values.requirements_training_apply_term) ===
                          1
                            ? true
                            : false
                        }
                        onChange={() =>
                          setFieldValue(
                            "requirements_training_apply_term",
                            values.requirements_training_apply_term ? 0 : 1,
                          )
                        }
                      />
                      <p className="d-inline mx-2">
                        Accept Terms &amp; Conditions
                      </p>
                      <span className="error-message mb-1 d-block">
                        {errors.requirements_training_apply_term &&
                        touched.requirements_training_apply_term
                          ? errors.requirements_training_apply_term
                          : null}
                      </span>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row">
                    <div className="col button">
                      <div className="d-flex  m-2 pull-right">
                        <button
                          type="submit"
                          form="trainingapplymodal"
                          className={`btn  btn-block mx-2 btn-${props.activeColor}`}
                          disabled={isSubmitting}
                        >
                          {" "}
                          {isSubmitting ? <FormLoader /> : <span>Apply</span>}
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-block"
                          onClick={closeModal}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  trainingApply: state.reqTraining.trainingApply,
});

export default connect(mapStateToProps, {
  setTrainingApplyData,
  applyTraining,
})(TrainingApplyModal);
