import React, { useState, useEffect, useMemo, Suspense } from "react";
import { useTable, usePagination, useExpanded } from "react-table";
import ServerGlobalFilter from "./ServerGlobalFilter";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faTrash,
  faUserPlus,
  faEnvelope,
  faPhone,
  faUser,
  faMobile,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { removeMember } from "../actions/contact";
import FormLoader from "./FormLoader";
import { connect } from "react-redux";
const GroupTable = ({
  data,
  batchParam,
  setBatchParam,
  setGrpRowData,
  activeMenu,
  columns,
  activeColor,
  removeMember,
}) => {
  const [pageSize, setPageSize] = useState(batchParam?.limit || 50);
  const [pageIndex, setPageIndex] = useState(
    batchParam?.offset ? Math.floor(batchParam?.offset / pageSize) + 1 : 1,
  );
  const [totalPages, setTotalPages] = useState(
    data[0]?.count ? Math.ceil(data[0]?.count / pageSize) : 1,
  );
  const [rowData, setRowData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(null);
  const pageSizeOptions = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: data[0]?.count, label: "All" },
  ];

  useEffect(() => {
    setTotalPages(Math.ceil(data[0]?.count / pageSize));
  }, [data, pageSize]);

  useEffect(() => {
    setPageIndex(1);
  }, [batchParam?.searchTerm]);

  useEffect(() => {
    if (pageIndex > 0 && pageIndex <= totalPages) {
      setBatchParam({
        ...batchParam,
        offset: (pageIndex - 1) * pageSize,
        limit: pageSize,
      });
    }
  }, [pageIndex, pageSize, totalPages, setBatchParam]);

  const isPaginationDisabled = data.length === 0;
  const confirmDelete = (row) => {
    setRowData(row);
    setIsOpen(true);
  };

  const handleDelete = () => {
    setShowLoader(true);
    let params = {
      group_id: rowData?.group_id,
      contact_id: rowData?.contact_id,
    };
    removeMember(params, setIsOpen, setShowLoader);
  };

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data: data,
        initialState: { pageSize },
      },
      useExpanded,
      usePagination,
    );

  return (
    <>
      <div className="d-flex justify-content-between">
        <ServerGlobalFilter
          globalFilter={batchParam?.searchTerm}
          setBatchParam={setBatchParam}
          batchParam={batchParam}
        />
        <span className="mx-2" />

        <span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPageIndex(1);
            }}
            className="form-control"
          >
            {pageSizeOptions.map((pageSizes, index) => (
              <option key={index} value={pageSizes.value}>
                Show {pageSizes.label}
              </option>
            ))}
          </select>
        </span>
      </div>

      <div className="table-striped table-responsive">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} key={column.id}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        key={cell.column.id}
                        className={row.isExpanded ? " text-primary bold" : ""}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                  {row.isExpanded && (
                    <tr>
                      <td
                        colSpan={columns.length}
                        style={{ marginLeft: "20px", border: "none" }}
                      >
                        {/* <h5>Group Members:</h5> */}
                        {row.original.members?.length > 0 ? (
                          <table className="table table-responsive mb-0 ">
                            {/* <thead>
                              <tr>
                                <th></th>
                                <th className="text-center">Name</th>
                                <th>Mobile Number</th>
                                <th>Email</th>
                                <th>Action</th>
                              </tr>
                            </thead> */}
                            <tbody>
                              {row.original.members.map((member, index) => (
                                <tr key={index}>
                                  <td className="table-width"></td>

                                  <td className=" w-25 text-truncate">
                                    {member.contact?.name && (
                                      <>
                                        <FontAwesomeIcon
                                          icon={faUser}
                                          className="text-muted mx-2 "
                                          style={{ fontSize: "14px" }}
                                        />{" "}
                                        {member.contact?.name}
                                      </>
                                    )}
                                  </td>
                                  <td
                                    className="mob-col text-truncate"
                                    style={{ width: "175px" }}
                                  >
                                    {member.contact?.phone && (
                                      <>
                                        <FontAwesomeIcon
                                          icon={faMobileAlt}
                                          className="text-muted mx-2 "
                                          style={{ fontSize: "14px" }}
                                        />{" "}
                                        {member.contact.phone}
                                      </>
                                    )}
                                  </td>
                                  <td className=" w-25 text-truncate">
                                    {member.contact?.email && (
                                      <>
                                        <FontAwesomeIcon
                                          icon={faEnvelope}
                                          className="text-muted mx-2 "
                                          style={{ fontSize: "14px" }}
                                        />{" "}
                                        {member.contact.email}
                                      </>
                                    )}
                                  </td>
                                  {activeMenu == "mygroup" ? (
                                    <td></td>
                                  ) : (
                                    <td className="text-center">
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="text-muted delete-margin "
                                        style={{ cursor: "pointer" }}
                                        onClick={() => confirmDelete(member)}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div>No members available for this group.</div>
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between">
        <div className=""></div>
        <div>
          Page{" "}
          <strong>
            {data[0]?.count ? (
              <>
                {pageIndex} of {totalPages}
              </>
            ) : (
              <>{pageIndex} of 1</>
            )}
          </strong>
        </div>
        <div className="pagination-icon">
          <i
            className="simple-icon-control-start"
            onClick={() =>
              !isPaginationDisabled && pageIndex > 1 && setPageIndex(1)
            }
            style={
              pageIndex === 1 ? { pointerEvents: "none", opacity: 0.5 } : {}
            }
          />
          <i
            className="simple-icon-arrow-left mx-2"
            onClick={() =>
              !isPaginationDisabled &&
              pageIndex > 1 &&
              setPageIndex(pageIndex - 1)
            }
            style={
              pageIndex === 1 ? { pointerEvents: "none", opacity: 0.5 } : {}
            }
          />
          <i
            className="simple-icon-arrow-right mx-2"
            onClick={() =>
              !isPaginationDisabled &&
              pageIndex < totalPages &&
              setPageIndex(pageIndex + 1)
            }
            style={
              pageIndex === totalPages
                ? { pointerEvents: "none", opacity: 0.5 }
                : {}
            }
          />
          <i
            className="simple-icon-control-end"
            onClick={() => !isPaginationDisabled && setPageIndex(totalPages)}
            style={
              pageIndex === totalPages
                ? { pointerEvents: "none", opacity: 0.5 }
                : {}
            }
          />
        </div>
      </div>
      <Suspense>
        <Modal
          show={isOpen}
          onHide={() => setIsOpen(false)}
          backdrop="static"
          keyboard={false}
          centered
          className="tj-modal delete-modal"
        >
          <Modal.Header closeButton className="d-flex">
            <Modal.Title className="flex-grow-1 text-center">
              Are you sure want to delete?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="d-flex flex-column align-items-center">
              {/* <div className="rounded-circle bg-light d-flex align-items-center justify-content-center modal-check-failure">
                <i className="fas fa-times-circle"></i>{" "}
              </div> */}
              If you delete this, you won't be able to recover the data.
            </div>
          </Modal.Body>
          <Modal.Footer className=" justify-content-center ">
            <button
              type="button"
              className={`btn w-25 mb-button my-1 btn-${activeColor}`}
              onClick={() => handleDelete()}
            >
              {showLoader ? <FormLoader /> : <>Yes</>}
            </button>
            <button
              type="button"
              className="btn mb-button mb-button-cancel w-25 my-1"
              onClick={() => setIsOpen(false)}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, { removeMember })(GroupTable);
