import { useState, useEffect, useRef, Suspense } from "react";

import { connect } from "react-redux";
import Emojify from "react-emojione";
import { BsPencilSquare } from "react-icons/bs";

import Smilies from "../../../../components/Smilies";
import { getUser } from "../../../../utils/UserDetails";
import { updateQboardPost } from "../../../../actions/qboard";
import { setJobApplyData, showJobApplyDetails } from "../../../../actions/job";
import {
  setSmilies,
  getComments,
  setCommentData,
} from "../../../../actions/dashboradCommon";
import {
  setTrainingApplyData,
  showTrainingApplyDetails,
} from "../../../../actions/reqTraining";
import LoginModal from "../../../AllUser/LoginModal";
import { WhatsappShareButton } from "react-share";

const PostCardFooter = (props) => {
  const [post, setPost] = useState();
  const currentUser = getUser();
  const [showSmilies, setShowSmilies] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const shareRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShowShareOptions(false);
      }
    };

    if (showShareOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showShareOptions]);

  useEffect(() => {
    setPost(props.post);
  }, [props.post]);

  useEffect(() => {
    setCommentsCount(props.post.common_comments.length);
  }, [props.post.common_comments]);

  const showAnswer = (obj) => {
    let updateData = {
      showAnswer: obj.showAnswer ? 0 : 1,
      qboard_id: obj.qboard_id,
    };
    props.updateQboardPost(updateData);
  };

  const handleSmiliesMouseHover = (isOpen) => {
    setShowSmilies(isOpen);
  };

  const showComments = (obj) => {
    let commentData = {
      showComment: obj.showComment ? 0 : 1,
      key_table: props.keyTable,
    };

    if (props.isFrom === "qboard") {
      commentData.post_id = post.qboard_id;
      commentData.qboard_id = post.qboard_id;
      commentData.qboard_parent_id = post.qboard_parent_id;
    } else if (props.isFrom === "jobs") {
      commentData.post_id = post.requirements_job_id;
      commentData.requirements_job_id = post.requirements_job_id;
    } else {
      commentData.post_id = post.requirements_training_id;
      commentData.requirements_training_id = post.requirements_training_id;
    }

    props.getComments(commentData);
  };

  const setSmilies = (smiliesName, like = 1) => {
    let smiliesData = {
      liketype:
        post.user_like && post.user_like.smilies_name === smiliesName
          ? 0
          : like,
      key_table: props.keyTable,
      smilies_name: smiliesName,
    };

    if (props.isFrom === "qboard") {
      smiliesData.post_id = post.qboard_id;
      smiliesData.qboard_id = post.qboard_id;
      smiliesData.qboard_parent_id = post.qboard_parent_id;
    } else if (props.isFrom === "jobs") {
      smiliesData.post_id = post.requirements_job_id;
      smiliesData.requirements_job_id = post.requirements_job_id;
    } else {
      smiliesData.post_id = post.requirements_training_id;
      smiliesData.requirements_training_id = post.requirements_training_id;
    }

    props.setSmilies(smiliesData);
  };

  const addComments = (post) => {
    let commentPostData = { ...props.postComment };
    commentPostData.isOpen = true;
    commentPostData.key_table = props.keyTable;
    commentPostData.qboard_id = "";
    commentPostData.qboard_parent_id = "";
    commentPostData.requirements_job_id = "";
    commentPostData.requirements_training_id = "";
    commentPostData.cmt_description = "";

    if (post.qboard_id) {
      commentPostData.qboard_id = post.qboard_id;
      commentPostData.qboard_parent_id = post.qboard_parent_id;
      commentPostData.post_id = post.qboard_id;
    } else if (post.requirements_job_id) {
      commentPostData.requirements_job_id = post.requirements_job_id;
      commentPostData.post_id = post.requirements_job_id;
    } else {
      commentPostData.requirements_training_id = post.requirements_training_id;
      commentPostData.post_id = post.requirements_training_id;
    }
    props.setCommentData(commentPostData);
  };

  const openJobApplyModal = (post) => {
    let jobApplyData = { ...props.jobApply };
    jobApplyData.requirements_job_apply_requirements_id =
      post.requirements_job_id;
    jobApplyData.requirements_job_apply_description =
      post.requirements_job_description;
    jobApplyData.isModalOpen = true;
    props.setJobApplyData(jobApplyData);
  };

  const openTrainingApplyModal = (post) => {
    let trainingApplyData = { ...props.trainingApply };
    trainingApplyData.requirements_training_apply_requirements_id =
      post.requirements_training_id;
    trainingApplyData.requirements_training_apply_description =
      post.requirements_training_description;
    trainingApplyData.requirements_training_apply_no_of_days =
      post.requirements_training_no_of_days;
    trainingApplyData.requirements_training_apply_startdate =
      post.requirements_training_startdate;
    trainingApplyData.requirements_training_apply_enddate =
      post.requirements_training_enddate;
    trainingApplyData.isModalOpen = true;
    props.setTrainingApplyData(trainingApplyData);
  };

  const viewJobApplication = (requirementId) => {
    const jobData = {
      requirement_id: requirementId,
    };
    props.showJobApplyDetails(jobData);
  };

  const viewTrainingApplication = (trainingID) => {
    const trainingData = {
      requirements_training_id: trainingID,
    };
    props.showTrainingApplyDetails(trainingData);
  };

  const handleShow = () => setShowModal(true);

  const handleClose = () => setShowModal(false);
  const toggleShareOptions = () => {
    setShowShareOptions(!showShareOptions);
  };

  return (
    <>
      {post ? (
        <div className={props.activeColor + `-icon border-top-for-feed`}>
          <div
            className="row pt-2 "
            onClick={() => (currentUser ? null : handleShow())}
          >
            {/* qboard div */}
            {props.isFrom === "qboard" && !post.qboard_parent_id ? (
              <div
                className="answer-padding col text-center"
                onClick={() => (currentUser ? showAnswer(post) : null)}
              >
                <img
                  className="answer-img height-18px"
                  src={process.env.PUBLIC_URL + "/images/menu-icons/answer.svg"}
                />
                {/* <BsPencilSquare className={props.activeColor} /> */}
                <span className="font-size-14px">
                  {" "}
                  Ans<span className="px-1">({post.parent?.length})</span>
                </span>
              </div>
            ) : null}

            {/* Jobs div */}
            {props.isFrom === "jobs" ? (
              <div className="answer-padding col text-center my-auto position-relative ">
                {post.isMyPost ? (
                  <span
                    className={`badge badge-primary btn-${props.activeColor}`}
                    onClick={() =>
                      currentUser
                        ? viewJobApplication(post.requirements_job_id)
                        : null
                    }
                  >
                    View Details({post.applied_job.length})
                  </span>
                ) : currentUser ? (
                  <span
                    className={`badge badge-primary btn-${props.activeColor}`}
                    onClick={() => {
                      !post.isApplied && openJobApplyModal(post);
                    }}
                  >
                    {!post.isApplied ? "Apply" : "You've  applied"}
                  </span>
                ) : (
                  <span
                    className={`badge badge-primary btn-${props.activeColor}`}
                    onClick={handleShow}
                  >
                    Apply
                  </span>
                )}
              </div>
            ) : null}

            {/* training div */}
            {props.isFrom === "training" ? (
              <div className="answer-padding p-2 col text-center apply-badges">
                {post.isMyPost ? (
                  <span
                    className={`badge badge-primary btn-${props.activeColor}`}
                    onClick={() =>
                      viewTrainingApplication(post.requirements_training_id)
                    }
                  >
                    View Details({post.applied_training.length})
                  </span>
                ) : currentUser ? (
                  <span
                    className={`badge badge-primary btn-${props.activeColor}`}
                    onClick={() => {
                      !post.isApplied && openTrainingApplyModal(post);
                    }}
                  >
                    {!post.isApplied ? "Apply" : "You've  Confirm"}
                  </span>
                ) : (
                  <span
                    className={`badge badge-primary btn-${props.activeColor}`}
                    onClick={handleShow}
                  >
                    Confirm
                  </span>
                )}
              </div>
            ) : null}

            <div
              className={`answer-padding col text-center my-auto position-relative `}
              onMouseEnter={() =>
                currentUser ? handleSmiliesMouseHover(true) : null
              }
              onMouseLeave={() =>
                currentUser ? handleSmiliesMouseHover(false) : null
              }
            >
              {currentUser && showSmilies ? (
                <Smilies
                  isChildren={post.qboard_parent_id}
                  setSmilies={setSmilies}
                />
              ) : null}

              {post.group_by_like.length ? (
                post.group_by_like.map((smilies) => (
                  <li className="list-inline-item">
                    <Emojify>
                      <span className="smilies-comment">
                        {smilies.split("-").shift()}
                        <span className="smilie-like-count px-1">
                          {smilies.split("-").pop()}
                        </span>
                      </span>
                    </Emojify>
                  </li>
                ))
              ) : (
                <i className="fas fa fa-thumbs-o-up"></i>
              )}
            </div>
            <div className="answer-padding col text-center">
              <img
                className="comment-img height-18px"
                src={process.env.PUBLIC_URL + "/images/menu-icons/comment.svg"}
                onClick={() => (currentUser ? addComments(post) : null)}
              />
              <span
                className="px-2 font-size-14px"
                onClick={() => (currentUser ? showComments(post) : null)}
              >
                ({commentsCount})
              </span>
            </div>
            <div
              className="answer-padding col text-center"
              style={{ position: "relative" }}
            >
              {/* Main Share Icon */}
              <i
                className="fas fa-share-alt"
                onClick={toggleShareOptions}
                style={{ cursor: "pointer", fontSize: "16px", zIndex: 1 }}
              ></i>

              {/* Floating WhatsApp & Email Icons */}
              <div
                ref={shareRef}
                style={{
                  position: "absolute",
                  top: "100%", // Position below the share icon
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 10,
                  display: showShareOptions ? "flex" : "none",
                  background: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                {/* WhatsApp Share */}
                <WhatsappShareButton
                  url={props?.getShareUrl()}
                  className="mx-2"
                  title="Click the link to view the post: "
                >
                  <i
                    className="fab fa-whatsapp"
                    style={{ color: "green", fontSize: "18px" }}
                  ></i>
                </WhatsappShareButton>

                {/* Email Share */}
                <a
                  href={`mailto:?subject=Check this out!&body=${props?.getShareUrl()}`}
                  className="mx-2"
                >
                  <i
                    className="fas fa-envelope"
                    style={{ color: "grey", fontSize: "16px" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <Suspense fallback={""}>
        <LoginModal show={showModal} handleClose={handleClose} />
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
  qboardPosts: state.qboard.qboardPost,
  keyTable: state.dashboardCommon.keyTable,
  postComment: state.dashboardCommon.postComment,
  jobApply: state.job.jobApply,
  trainingApply: state.reqTraining.trainingApply,
});

export default connect(mapStateToProps, {
  updateQboardPost,
  setSmilies,
  getComments,
  setCommentData,
  setJobApplyData,
  showJobApplyDetails,
  setTrainingApplyData,
  showTrainingApplyDetails,
})(PostCardFooter);
