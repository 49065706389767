import {
  SET_DEVICEDATA,
  SET_FITFORROLE,
  SET_ROADMAP,
  VERIFY_LIMIT,
} from "./types";
import CareerService from "../services/Carrer.Service";
import { toast } from "react-toastify";

export const getroadmap =
  (data, setLoading, careerid, history) => async (dispatch) => {
    try {
      const categoriesdata = await CareerService.getroadmap(data);

      if (setLoading) {
        setLoading(false);
      }

      if (categoriesdata?.status) {
        localStorage.setItem(
          "limitData",
          JSON.stringify({
            source: "career-roadmap",
            limit: 2,
          }),
        );

        if (careerid && history) {
          history.push("/student/roadmap");
        }
        dispatch({
          type: SET_ROADMAP,
          payload: categoriesdata?.data?.data,
        });
      } else {
        toast.error("Please Try Again");
      }
    } catch (err) {
      console.log(err);
      toast.error("Please Try Again");
    }
  };

export const fitForRole =
  (data, setLoading, fitid, history) => async (dispatch) => {
    try {
      const resultData = await CareerService.fitForRole(data);
      if (setLoading) {
        setLoading(false);
      }

      if (resultData?.status) {
        localStorage.setItem(
          "limitforfitrole",
          JSON.stringify({
            source: "fit-role",
            limit: 2,
          }),
        );
        // if (fitid && history) {
        //   history.push("/student/fit-for-role");
        // }

        dispatch({
          type: SET_FITFORROLE,
          payload: resultData,
        });
      } else {
        toast.error("Please Try Again");
      }
    } catch (err) {
      console.log(err);
      toast.error("Please Try Again");
    }
  };

export const roadmapClick = (uniqueIdentifier, device) => async (dispatch) => {
  try {
    const devicedata = await CareerService.deviceInfo(uniqueIdentifier, device);

    dispatch({
      type: SET_DEVICEDATA,
      payload: devicedata,
    });
  } catch (err) {
    console.log(err);
  }
};

export const VerifyLimit = (params) => async (dispatch) => {
  try {
    const resultData = await CareerService.VerifyLimit(params);
    dispatch({
      type: VERIFY_LIMIT,
      payload: resultData,
    });
  } catch (err) {
    console.log(err);
  }
};
