import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import FormLoader from "../../../components/FormLoader";

const ExitConfirmModal = ({
  isOpen,
  setIsOpen,
  activeColor,
  handleExitGroup,
  showLoader,
}) => {
  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      backdrop="static"
      keyboard={false}
      centered
      className="tj-modal delete-modal"
    >
      <Modal.Header closeButton className="d-flex">
        <Modal.Title className="flex-grow-1 text-center">
          Are you sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="d-flex flex-column align-items-center">
          {/* <div className="rounded-circle bg-light d-flex align-items-center justify-content-center modal-check-failure">
            <i className="fas fa-times-circle"></i>{" "}
          </div> */}
          If you exit this group , You can't rejoin without an invite.
        </div>
      </Modal.Body>
      <Modal.Footer className=" justify-content-center ">
        <button
          type="button"
          className={`btn w-25 mb-button my-1 btn-${activeColor}`}
          onClick={() => handleExitGroup()}
        >
          {showLoader ? <FormLoader /> : <>Yes</>}
        </button>
        <button
          type="button"
          className="btn mb-button mb-button-cancel w-25 my-1"
          onClick={() => setIsOpen(false)}
        >
          No
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, {})(ExitConfirmModal);
