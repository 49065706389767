import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { BsArrowLeft } from "react-icons/bs";

import JobDescriptionPreview from "./JobDescriptionPreview";
import { resetJobAIDetails } from "../../../../../actions/job";
import Layout from "../../../../../components/layout/Layout";
import { getUser } from "../../../../../utils/UserDetails";

const JobDescriptionProcessing = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [skills, setSkills] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [workEnvironment, setWorkEnvironment] = useState("");
  const [salary, setSalary] = useState("");
  const [applicationInstructions, setApplicationInstructions] = useState("");
  const [jdData, setJDData] = useState([]);
  const [jdText, setJDText] = useState([]);
  const [improveJDData, setImproveJDData] = useState([]);
  const [selectedImprovements, setSelectedImprovements] = useState([]);
  const [isJdView, setIsJdView] = useState(true);

  useEffect(() => {
    if (props?.jobAiDetails?.jd_data) {
      if (props?.jobAiDetails?.jd_data?.actual_criteria_in_given_jd?.criteria) {
        setJDData(
          props.jobAiDetails.jd_data.actual_criteria_in_given_jd.criteria,
        );
      }

      if (
        props?.jobAiDetails?.jd_data?.criteria_that_can_be_improved?.suggestions
      ) {
        setImproveJDData(
          props.jobAiDetails.jd_data.criteria_that_can_be_improved.suggestions,
        );
      }

      if (props?.jobAiDetails?.jd_text) {
        setJDText(props?.jobAiDetails?.jd_text);
      }
    } else {
      if (getUser()) {
        history.push("/recruiter/home");
      } else {
        history.push("/explore/recruiter");
      }
    }
  }, [props?.jobAiDetails]);

  const handleCheckboxChange = (index, isChecked) => {
    setImproveJDData((prev) => {
      const newState = [...prev];
      newState[index].isChecked = isChecked; // Update checkbox state in improveJDData
      return newState;
    });

    setSelectedImprovements((prevState) => {
      const newState = [...prevState];

      if (isChecked) {
        // Add the selected improvement with its current input value
        const selectedItem = {
          criterion: improveJDData[index]?.criterion,
          improvementSuggestion: improveJDData[index]?.improvement_suggestion,
          inputValue: improveJDData[index]?.inputValue || "", // Preserve any input value
        };
        newState[index] = selectedItem;
      } else {
        // If unchecked, remove from selected improvements
        newState[index] = null;
      }
      return newState;
    });
  };

  const handleInputChange = (index, value) => {
    setImproveJDData((prev) => {
      const newState = [...prev];
      newState[index].inputValue = value; // Update the input value in improveJDData
      return newState;
    });

    setSelectedImprovements((prevState) => {
      const newState = [...prevState];

      if (newState[index]) {
        newState[index].inputValue = value; // Update input value in selected improvements
      }
      return newState;
    });
  };

  const handleAddImprovements = () => {
    const checkedImprovements = selectedImprovements.filter(
      (item) => item !== null && item !== undefined,
    );

    const newImprovements = checkedImprovements.map((item) => ({
      criterion_name: item.criterion,
      criterion_value: item.inputValue,
    }));

    const updatedJDData = [...jdData, ...newImprovements];
    setJDData(updatedJDData);

    const updatedImproveJDData = improveJDData.filter(
      (_, index) => !selectedImprovements[index],
    );

    setImproveJDData(updatedImproveJDData);

    setImproveJDData((prev) =>
      prev.map((obj) => ({
        ...obj,
        isChecked: false,
        inputValue: "",
      })),
    );

    setSelectedImprovements([]);
  };

  const handlePreviewClick = () => {
    setIsJdView(false);
  };

  const onBack = () => {
    dispatch(resetJobAIDetails());
  };

  const isValid =
    improveJDData.some((obj) => obj.isChecked) &&
    improveJDData.every(
      (obj) =>
        !obj.isChecked ||
        (obj.isChecked && "inputValue" in obj && obj.inputValue !== ""),
    );

  return (
    <Layout>
      {isJdView ? (
        <div className="job-description-processing">
          <button
            className="btn btn-primary rounded-circle d-flex align-items-center justify-content-center m-3"
            onClick={onBack}
            style={{ width: "40px", height: "40px" }}
          >
            <BsArrowLeft className="arrow-icon" />
          </button>

          <div className="container">
            <div className="left-section">
              <div
                className={`section-title ${props.activeColor}-font text-center mb-3`}
              >
                Job description processing result
              </div>
              <table className="criterion-table">
                <thead>
                  <tr>
                    <th>Criteria</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {jdData?.length > 0 &&
                    jdData.map((obj, index) => (
                      <tr key={index}>
                        <th>{obj?.criterion_name}</th>
                        <td>{obj?.criterion_value}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="col button">
                <div className="d-flex  m-2">
                  <button
                    type="submit"
                    className={`btn  btn-block m-2 btn-${props.activeColor}`}
                    onClick={handlePreviewClick}
                  >
                    Preview
                  </button>
                </div>
              </div>
            </div>

            <div className="right-section">
              <div
                className={`section-title ${props.activeColor}-font text-center mb-3`}
              >
                Select criteria that can be used to improve your JD
              </div>

              {improveJDData?.length > 0 &&
                improveJDData.map((obj, index) => {
                  const showError =
                    obj.isChecked &&
                    (!obj.inputValue || obj.inputValue.trim() === "");

                  return (
                    <div key={index} className="select-improvement form-group">
                      <label htmlFor="skills">{obj?.criterion}: </label>
                      <small className="jd-instruction form-text text-muted">
                        {obj?.improvement_suggestion}
                      </small>
                      <div className="input-group mb-1 mt-1">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <input
                              type="checkbox"
                              aria-label="Checkbox for following text input"
                              checked={obj.isChecked || false}
                              onChange={(e) =>
                                handleCheckboxChange(index, e.target.checked)
                              }
                            />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          aria-label="Text input with checkbox"
                          value={obj.inputValue || ""}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          disabled={!obj.isChecked} // Disable input if checkbox is not checked
                        />
                      </div>
                      <div className="err-height">
                        <span className="error-message">
                          {showError &&
                            "This field is required when the checkbox is checked."}{" "}
                        </span>
                      </div>
                    </div>
                  );
                })}
              <div className="button-container">
                <button
                  className={`btn btn-block mx-2 btn-${props.activeColor}`}
                  onClick={handleAddImprovements}
                  disabled={improveJDData?.length === 0 || !isValid}
                >
                  Add Selected Improvements
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <JobDescriptionPreview
          setIsJdView={setIsJdView}
          jdData={jdData}
          jdText={jdText}
          jobAiDetails={props?.jobAiDetails}
          activeColor={props.activeColor}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  jobAiDetails: state.job.jobAiDetails,
});

export default connect(mapStateToProps, null)(JobDescriptionProcessing);
