import { enrollCourse } from "../actions/training";
import {
  CREATE_MENUS,
  GET_LANGUAGES,
  GET_MENUS,
  GET_LEVELS,
  GET_TRAINING_CATEGORY,
  GET_COURSES,
  GET_ALL_COURSES,
  DELETE_COURSE,
  CREATE_COURSE,
  ADD_BATCH,
  DELETE_SESSION,
  GET_CURRENCIES,
  ADD_SESSION,
  ADD_ASSOCIATE,
  DELETE_ASSOCIATE,
  ADD_FINANCE,
  DELETE_FINANCE,
  ENROLL_COURSE,
  POST_ENROLL,
  ADD_FAVOURITE,
} from "../actions/types";

const reducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MENUS: {
      return {
        ...state,
        menus: payload,
      };
    }
    case CREATE_MENUS: {
      return {
        ...state,
        newMenus: payload,
      };
    }
    case GET_LANGUAGES: {
      return {
        ...state,
        languages: payload,
      };
    }
    case GET_LEVELS: {
      return {
        ...state,
        levels: payload,
      };
    }
    case GET_TRAINING_CATEGORY: {
      return {
        ...state,
        trainingCategory: payload,
      };
    }
    case GET_COURSES: {
      return {
        ...state,
        userCourses: payload,
      };
    }
    case POST_ENROLL: {
      return {
        ...state,
        enrollCourse: payload,
      };
    }
    case GET_ALL_COURSES: {
      return {
        ...state,
        userAllCourses: payload,
      };
    }
    case ADD_FAVOURITE: {
      const favCourseId = payload?.favourite?.course_id
        ? Number(payload?.favourite?.course_id)
        : Number(payload?.course_id);

      const updatedCourses = state.userAllCourses.map((course) =>
        course.course_id === favCourseId
          ? {
              ...course,
              favourite: payload.favourite?.favourite_course_enable
                ? payload.favourite
                : null,
            }
          : { ...course },
      );
      return {
        ...state,
        userAllCourses: updatedCourses,
      };
    }
    case DELETE_COURSE: {
      return {
        ...state,
        delCourse: payload,
      };
    }
    case CREATE_COURSE: {
      return {
        ...state,
        createCourse: payload,
        userCourses: payload.data,
      };
    }
    case ADD_BATCH: {
      return {
        ...state,
        batch: payload,
      };
    }
    case DELETE_SESSION: {
      return {
        ...state,
        deleteSession: payload,
      };
    }
    case GET_CURRENCIES: {
      return {
        ...state,
        currencies: payload,
      };
    }
    case ADD_SESSION: {
      return {
        ...state,
        session: payload,
        userCourses: payload.data,
      };
    }
    case ADD_ASSOCIATE: {
      return {
        ...state,
        associate: payload,
      };
    }
    case DELETE_ASSOCIATE: {
      return {
        ...state,
        deleteAsso: payload,
      };
    }
    case ADD_FINANCE: {
      return {
        ...state,
        finance: payload,
      };
    }
    case DELETE_FINANCE: {
      return {
        ...state,
        deleteFinan: payload,
      };
    }
    case ENROLL_COURSE: {
      return {
        ...state,
        enrollres: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
