import React, { Suspense, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { addFavourite, getAllCourses } from "../../../../../actions/training";
import ConfirmModal from "../ConfirmModal";
import { getUserRole } from "../../../../../utils/UserDetails";
import { WhatsappShareButton } from "react-share";
import { ArrowRight, Clock4, List, Users } from "lucide-react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

toast.configure();

const Classroom = (props) => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [showStar, setshowStar] = useState(1);
  const [starFlags, setStarFlags] = useState({});
  const shareRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    setCourseData(props.courses);
  }, [props.courses]);

  useEffect(() => {
    if (props?.searchQuery == "") {
      setCourseData(props.courses || []);
    } else {
      const filteredCourses = courseData.filter((course) =>
        course.course_name
          .toLowerCase()
          .includes(props?.searchQuery.toLowerCase()),
      );

      setCourseData(filteredCourses);
    }
  }, [props?.searchQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShowShareOptions(false);
      }
    };

    if (showShareOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showShareOptions]);

  useEffect(() => {
    if (props.mainFilter.tab_view) {
      if (props.mainFilter?.category_id !== "") {
        let params = {
          course_mode: "classroom",
          interest_id: props.mainFilter?.category_id,
        };
        props.getAllCourses(setLoading, params);
      } else {
        let params = {
          course_mode: "classroom",
          star_filter: props.mainFilter?.star_filter,
        };
        props.getAllCourses(setLoading, params);
      }
    }
  }, [props.mainFilter]);

  const calculateOfferPricePercentage = (
    courseFee,
    courseDiscountPercentage,
  ) => {
    return courseFee - (courseFee * courseDiscountPercentage) / 100;
  };

  const handleCourseDetails = (data) => {
    const userRole = getUserRole()?.toLowerCase();
    window.open(`/${userRole}/course-details/${data?.course_id}`, "_blank");
    // history.push(`/${userRole}/course-details`, { course_data: data })
  };

  const toggleShareOptions = (index, e) => {
    e.stopPropagation(); // Prevent event bubbling
    setShowShareOptions(showShareOptions === index ? null : index);
  };

  const onFavouriteChangeHandler = (course, favourite_course_id = null) => {
    let favouriteData = {};
    favouriteData.favourite_course_id = course?.favourite?.favourite_course_id;
    favouriteData.course_id = course?.course_id;
    props.addFavourite(favouriteData);
  };

  return (
    <div className="position-relative">
      <div className="row">
        {courseData?.length > 0 ? (
          courseData.map((course, index) => (
            <div
              key={index}
              className="col-12 col-sm-6  mb-4"
              // onMouseEnter={() => setHoveredCard(index)}
              // onMouseLeave={() => setHoveredCard(null)}
            >
              <div
                className="card p-2 course-card"
                style={{
                  position: "relative",
                  height: hoveredCard == index ? "350px" : null,
                  transition: "height 0.3s ease",
                }}
              >
                {hoveredCard === index ? (
                  <></>
                ) : (
                  <>
                    <div className="card-img " style={{ height: "200px" }}>
                      <img
                        className="course-card-img-top card-img-top img-fluid "
                        src={
                          course.course_image
                            ? course.course_image
                            : "/images/pexels-katerina-holmes-5905709.jpg"
                        }
                        alt={course.title}
                        height={200}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "/images/pexels-katerina-holmes-5905709.jpg";
                        }}
                      />
                    </div>

                    <div className="card-body course-card-content mt-3">
                      <div style={{ height: "70%" }}>
                        <div className="course-price-div justify-content-between align-items-center mb-3">
                          <div>
                            <span className="course-language-css">
                              {course?.interest?.interests_name
                                ? course?.interest?.interests_name
                                : null}
                            </span>
                          </div>{" "}
                          <div className="col-1 ms-1">
                            <i
                              className={
                                "fas fa fa-star my-2 " +
                                (course?.favourite?.favourite_course_enable ===
                                1
                                  ? "highlight-star"
                                  : "dull-star")
                              }
                              onClick={() =>
                                onFavouriteChangeHandler(
                                  course,
                                  course?.favourite?.favourite_course_enable
                                    ? course?.favourite?.favourite_course_id
                                    : null,
                                )
                              }
                            ></i>
                          </div>
                          <div
                            className="answer-padding col text-end"
                            style={{ position: "relative" }}
                          >
                            <i
                              className="fas fa-share-alt"
                              onClick={(e) => toggleShareOptions(index, e)}
                              style={{
                                cursor: "pointer",
                                fontSize: "16px",
                                zIndex: 1,
                              }}
                            ></i>{" "}
                            <div
                              ref={shareRef}
                              style={{
                                position: "absolute",
                                top: "100%", // Position below the share icon
                                left: "80%",
                                transform: "translateX(-50%)",
                                zIndex: 10,
                                display:
                                  showShareOptions === index ? "flex" : "none",
                                background: "white",
                                padding: "5px",
                                borderRadius: "5px",
                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                              }}
                            >
                              {/* WhatsApp Share */}
                              <WhatsappShareButton
                                url={`${
                                  process.env.REACT_APP_FRONTEND_BASEURL
                                }/${getUserRole()?.toLowerCase()}/course-details/${
                                  course?.course_id
                                }`}
                                className="mx-2"
                                title="Click the link to view the Course: "
                              >
                                <i
                                  className="fab fa-whatsapp"
                                  style={{ color: "green", fontSize: "18px" }}
                                ></i>
                              </WhatsappShareButton>

                              {/* Email Share */}
                              <a
                                href={`mailto:?subject=Check this out!&body=${`${
                                  window.location.origin
                                }/${getUserRole()?.toLowerCase()}/course-details/${
                                  course?.course_id
                                }`}`}
                                className="mx-2"
                              >
                                <i
                                  className="fas fa-envelope"
                                  style={{ color: "grey", fontSize: "16px" }}
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="course-price-div justify-content-between mb-3">
                          {course.course_instructionallevel ? (
                            <div className="d-flex flex-row align-items-center">
                              <List className="online-icon-css" />
                              <span className="course-list-name-css">
                                {course.course_instructionallevel
                                  ? course.course_instructionallevel
                                  : "-"}
                              </span>
                            </div>
                          ) : null}
                          <div className="d-flex flex-row align-items-center">
                            <Users className="online-icon-css" />
                            <span className="course-list-name-css">
                              {course?.batches[0]?.coursebatch_studentsperbatch
                                ? course?.batches[0]
                                    ?.coursebatch_studentsperbatch + " Students"
                                : null}
                            </span>
                          </div>
                          {course.course_totalhours ? (
                            <div className="d-flex flex-row align-items-center">
                              <Clock4 className="online-icon-css" />
                              <span className="course-list-name-css">
                                {course.course_totalhours
                                  ? course.course_totalhours + " hours"
                                  : "-"}{" "}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <span className="course-title-css">
                            {course?.course_name}
                            {course?.course_subtitle
                              ? ": " + course?.course_subtitle
                              : null}
                          </span>
                        </div>
                        <div>
                          <span className="course-description-css">
                            {course.course_shortdescription
                              ? course.course_shortdescription
                              : null}
                          </span>
                        </div>
                      </div>
                      <div style={{ height: "30%" }}>
                        <div className="course-author">
                          <div className="thumb-img">
                            <img
                              src={
                                course.profile_image
                                  ? course.profile_image
                                  : "/images/no-img.webp"
                              }
                              height={200}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/images/no-img.webp";
                              }}
                            />
                          </div>
                          <span className="course-trainer-name-css">
                            {course?.creator_name}
                          </span>
                        </div>
                        <div className="course-price-div align-items-center">
                          <div>
                            <span className="course-venue-title">Venue :</span>
                          </div>
                          <div>
                            <span className="course-venue">
                              {course?.course_venue}
                            </span>
                          </div>
                        </div>
                        <div className="course-price-div justify-content-between">
                          <div className="course-price-div">
                            <div className="course-price-css">
                              <span>
                                {course.course_fee !== null
                                  ? course.course_discount !== null
                                    ? "₹" +
                                      calculateOfferPricePercentage(
                                        course.course_fee,
                                        course.course_discount,
                                      )
                                    : "₹" + course.course_fee
                                  : "₹" + 0}
                              </span>
                            </div>
                            <div className="course-base-price-css">
                              <span>
                                {course.course_discount !== null
                                  ? "₹" + course.course_fee
                                  : null}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCourseDetails(course)}
                          >
                            <span className="learn-more-css">Learn More </span>
                            <ArrowRight
                              style={{
                                width: "15px",
                                height: "15px",
                                color: "#00a5f0",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">No Data Found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  courses: state.training.userAllCourses,
  enrollres: state.training.enrollres,
  activeColor: state.activetheme.activeColor,
  mainFilter: state.dashboardCommon.mainFilter,
});

export default connect(mapStateToProps, { getAllCourses, addFavourite })(
  Classroom,
);
