import React, { useState, useEffect } from "react";
import { AlertCircle, X, CircleCheckBig, CircleX, Info } from "lucide-react";
import { Alert } from "react-bootstrap";
import { getPlanAlert } from "../../actions/common";
import { connect } from "react-redux";
import { getUserRoleId } from "../../utils/UserDetails";

const CommonAlert = ({ getPlanAlert, alertPlanData }) => {
  const [alertData, setAlertData] = useState([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    getPlanAlert({ role_id: getUserRoleId() });

    const lastClosedDate = localStorage.getItem("alertClosedDate");
    const today = new Date().toISOString().split("T")[0];

    if (lastClosedDate === today) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    if (alertPlanData?.status) {
      setAlertData(alertPlanData);
    }
  }, [alertPlanData]);

  const handleClose = () => {
    setShow(false);

    const today = new Date().toISOString().split("T")[0];
    localStorage.setItem("alertClosedDate", today);
  };

  return (
    <>
      {alertPlanData?.status && show ? (
        <Alert variant={alertData?.alert}>
          <div className="d-flex align-items-center">
            {alertData?.alert === "info" ? (
              <Info className="alert-icon-css" />
            ) : alertData?.alert === "warning" ? (
              <AlertCircle className="alert-icon-css " />
            ) : alertData?.alert === "danger" ? (
              <CircleX className="alert-icon-css" />
            ) : (
              <CircleCheckBig className="alert-icon-css success-icon" />
            )}
            <span className="alert-content-css">
              <Alert.Link href="/subscription" target="_blank">
                {" "}
                <strong>
                  {alertData?.alert === "warning"
                    ? "Upgrade Today !"
                    : alertData?.alert === "info"
                    ? "Subscribe Now !"
                    : alertData?.alert === "danger"
                    ? "Upgrade Now !"
                    : "Check Here !"}
                </strong>
              </Alert.Link>
              &nbsp;
              {alertData?.message}
            </span>
            <X className="ms-auto alert-close-icon" onClick={handleClose} />
          </div>
        </Alert>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  alertPlanData: state.common.alertPlanData,
});

export default connect(mapStateToProps, { getPlanAlert })(CommonAlert);
